import React from 'react';
import { format } from 'date-fns';
import { MapPin, Calendar, Euro, SearchX, Clock } from 'lucide-react';
import { Event } from '../types/event';
import EventActions from './EventActions';

interface EventListProps {
  events: Event[];
  onEventClick: (event: Event) => void;
  displayMode?: 'grid' | 'list';
  showManageButton?: boolean;
  showUnregisterButton?: boolean;
}

const EventCard = React.memo(({ 
  event, 
  onClick,
  displayMode = 'grid',
  showManageButton,
  showUnregisterButton 
}: { 
  event: Event; 
  onClick: () => void;
  displayMode?: 'grid' | 'list';
  showManageButton?: boolean;
  showUnregisterButton?: boolean;
}) => {
  const isPastEvent = new Date(event.endDate) < new Date();

  if (displayMode === 'list') {
    return (
      <div
        className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transform transition-transform hover:scale-[1.01] h-32 flex"
        onClick={onClick}
      >
        <div className="w-48 flex-shrink-0">
          <img
            src={event.imageUrl || 'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?auto=format&fit=crop&w=800'}
            alt={event.title}
            className="w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div className="flex-1 p-4 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start">
              <div>
                {isPastEvent && (
                  <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-neutral-100 text-neutral-800 mb-2">
                    <Clock className="h-3 w-3 mr-1" />
                    Past Event
                  </span>
                )}
                <h3 className="text-lg font-semibold text-gray-900 mb-1 line-clamp-1">
                  {event.title}
                </h3>
                <p className="text-sm text-gray-600 line-clamp-1 mb-2">{event.description}</p>
              </div>
              {event.price && (
                <div className="ml-4 bg-white px-2 py-1 rounded-full shadow-sm border flex-shrink-0">
                  <div className="flex items-center space-x-1">
                    <Euro className="h-4 w-4 text-green-600" />
                    <span className="text-sm font-medium text-gray-900">
                      {event.price.min === event.price.max
                        ? `${event.price.min}`
                        : `${event.price.min} - ${event.price.max}`}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4 text-sm text-gray-600">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-1" />
                <span>{format(new Date(event.startDate), 'MMM d')}</span>
              </div>
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-1" />
                <span className="truncate max-w-[200px]">{event.location.name}</span>
              </div>
            </div>
            <EventActions eventId={event.id} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="h-full bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transform transition-transform hover:scale-[1.02]"
      onClick={onClick}
    >
      <div className="relative h-48">
        <img
          src={event.imageUrl || 'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?auto=format&fit=crop&w=800'}
          alt={event.title}
          className="w-full h-full object-cover"
          loading="lazy"
        />
        {event.price && (
          <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-full shadow-md">
            <div className="flex items-center space-x-1">
              <Euro className="h-3 w-3 sm:h-4 sm:w-4 text-green-600" />
              <span className="text-xs sm:text-sm font-medium text-gray-900">
                {event.price.min === event.price.max
                  ? `${event.price.min}`
                  : `${event.price.min} - ${event.price.max}`}
              </span>
            </div>
          </div>
        )}
        <div className="absolute top-2 left-2">
          <EventActions eventId={event.id} />
        </div>
      </div>
      
      <div className="p-4">
        {isPastEvent && (
          <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-neutral-100 text-neutral-800 mb-2">
            <Clock className="h-3 w-3 mr-1" />
            Past Event
          </span>
        )}
        <h3 className="text-lg font-semibold text-gray-900 mb-2 line-clamp-2 min-h-[3.5rem]">
          {event.title}
        </h3>
        
        <div className="space-y-2">
          <div className="flex items-center text-gray-600">
            <Calendar className="h-4 w-4 mr-2 flex-shrink-0" />
            <span className="text-sm truncate">
              {format(new Date(event.startDate), 'EEEE, MMMM d')}
            </span>
          </div>
          
          <div className="flex items-center text-gray-600">
            <MapPin className="h-4 w-4 mr-2 flex-shrink-0" />
            <span className="text-sm truncate">{event.location.name}</span>
          </div>
        </div>
        
        <div className="mt-4 flex flex-wrap gap-2">
          {Array.isArray(event.categories) ? event.categories.map((category) => (
            <span 
              key={category}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-800"
            >
              {category}
            </span>
          )) : (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-800">
              {event.category}
            </span>
          )}
        </div>

        {(showManageButton || showUnregisterButton) && (
          <div className="mt-3 pt-3 border-t">
            {showManageButton && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = `/event/${event.id}/edit`;
                }}
                className="w-full text-center text-sm text-brand-600 hover:text-brand-800"
              >
                Manage Event
              </button>
            )}
            {showUnregisterButton && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  // Add unregister logic
                }}
                className="w-full text-center text-sm text-brand-600 hover:text-brand-800"
              >
                Unregister
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

EventCard.displayName = 'EventCard';

function EventList({ 
  events = [], 
  onEventClick,
  displayMode = 'grid',
  showManageButton,
  showUnregisterButton 
}: EventListProps) {
  if (!Array.isArray(events)) {
    console.error('Events prop is not an array:', events);
    return <div>Error loading events</div>;
  }

  if (events.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-64 px-4 text-center">
        <SearchX className="h-12 w-12 text-gray-400 mb-4" />
        <p className="text-lg text-gray-600 mb-2">No events found</p>
        <p className="text-sm text-gray-500">Try adjusting your filters or search criteria</p>
      </div>
    );
  }

  return (
    <div 
      className={
        displayMode === 'list'
          ? 'space-y-4'
          : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'
      }
    >
      {events.map((event) => (
        <EventCard
          key={event.id}
          event={event}
          onClick={() => onEventClick(event)}
          displayMode={displayMode}
          showManageButton={showManageButton}
          showUnregisterButton={showUnregisterButton}
        />
      ))}
    </div>
  );
}

export default EventList;