import { 
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
  Timestamp,
  increment,
  orderBy
} from 'firebase/firestore';
import { db } from './firebase';
import { Event } from '../types/event';
import { fallbackEvents } from '../data/fallbackEvents';
import { generateEventId } from '../utils/slugify';

// Main event search function
export const searchEvents = async ({ 
  filters = {}
}: { 
  filters?: {
    status?: string;
  }
} = {}): Promise<Event[]> => {
  try {
    if (!navigator.onLine) {
      return fallbackEvents;
    }

    const eventsRef = collection(db, 'events');
    const q = query(
      eventsRef,
      where('status', '==', filters.status || 'approved')
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate instanceof Timestamp ? 
        doc.data().startDate.toDate().toISOString() : 
        doc.data().startDate,
      endDate: doc.data().endDate instanceof Timestamp ? 
        doc.data().endDate.toDate().toISOString() : 
        doc.data().endDate,
      createdAt: doc.data().createdAt instanceof Timestamp ? 
        doc.data().createdAt.toDate().toISOString() : 
        doc.data().createdAt,
      updatedAt: doc.data().updatedAt instanceof Timestamp ? 
        doc.data().updatedAt.toDate().toISOString() : 
        doc.data().updatedAt,
      moderatedAt: doc.data().moderatedAt instanceof Timestamp ? 
        doc.data().moderatedAt.toDate().toISOString() : 
        doc.data().moderatedAt,
      location: {
        ...doc.data().location,
        latitude: String(doc.data().location.latitude),
        longitude: String(doc.data().location.longitude)
      },
      categories: Array.isArray(doc.data().categories) ? doc.data().categories : [doc.data().category],
      maxAttendees: String(doc.data().maxAttendees || ''),
      shares: Number(doc.data().shares || 0)
    } as Event));
  } catch (error) {
    console.error('Error fetching events:', error);
    return [];
  }
};

// Get single event by ID
export const getEventById = async (eventId: string): Promise<Event | null> => {
  try {
    if (!navigator.onLine) {
      return fallbackEvents.find(event => event.id === eventId) || null;
    }

    const eventRef = doc(db, 'events', eventId);
    const eventDoc = await getDoc(eventRef);
    
    if (!eventDoc.exists()) {
      return null;
    }

    const data = eventDoc.data();
    return {
      id: eventDoc.id,
      ...data,
      startDate: data.startDate instanceof Timestamp ? 
        data.startDate.toDate().toISOString() : 
        data.startDate,
      endDate: data.endDate instanceof Timestamp ? 
        data.endDate.toDate().toISOString() : 
        data.endDate,
      createdAt: data.createdAt instanceof Timestamp ? 
        data.createdAt.toDate().toISOString() : 
        data.createdAt,
      updatedAt: data.updatedAt instanceof Timestamp ? 
        data.updatedAt.toDate().toISOString() : 
        data.updatedAt,
      moderatedAt: data.moderatedAt instanceof Timestamp ? 
        data.moderatedAt.toDate().toISOString() : 
        data.moderatedAt,
      location: {
        ...data.location,
        latitude: String(data.location.latitude),
        longitude: String(data.location.longitude)
      },
      categories: Array.isArray(data.categories) ? data.categories : [data.category],
      maxAttendees: String(data.maxAttendees || ''),
      shares: Number(data.shares || 0)
    } as Event;
  } catch (error) {
    console.error('Error getting event:', error);
    return null;
  }
};

// Get user's events
export const getUserEvents = async (userId: string): Promise<Event[]> => {
  try {
    if (!navigator.onLine) {
      return fallbackEvents.filter(event => event.organizer.id === userId);
    }

    const eventsRef = collection(db, 'events');
    const q = query(
      eventsRef,
      where('organizer.id', '==', userId),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate instanceof Timestamp ? 
        doc.data().startDate.toDate().toISOString() : 
        doc.data().startDate,
      endDate: doc.data().endDate instanceof Timestamp ? 
        doc.data().endDate.toDate().toISOString() : 
        doc.data().endDate,
      createdAt: doc.data().createdAt instanceof Timestamp ? 
        doc.data().createdAt.toDate().toISOString() : 
        doc.data().createdAt,
      updatedAt: doc.data().updatedAt instanceof Timestamp ? 
        doc.data().updatedAt.toDate().toISOString() : 
        doc.data().updatedAt,
      moderatedAt: doc.data().moderatedAt instanceof Timestamp ? 
        doc.data().moderatedAt.toDate().toISOString() : 
        doc.data().moderatedAt,
      location: {
        ...doc.data().location,
        latitude: String(doc.data().location.latitude),
        longitude: String(doc.data().location.longitude)
      },
      categories: Array.isArray(doc.data().categories) ? doc.data().categories : [doc.data().category],
      maxAttendees: String(doc.data().maxAttendees || ''),
      shares: Number(doc.data().shares || 0)
    } as Event));
  } catch (error) {
    console.error('Error getting user events:', error);
    return [];
  }
};

// Create new event
export const createEvent = async (eventData: Omit<Event, 'id'>): Promise<Event> => {
  try {
    const eventsRef = collection(db, 'events');
    
    // Generate unique ID from title
    const eventId = await generateEventId(eventData.title);
    
    const eventToCreate = {
      id: eventId,
      ...eventData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: eventData.status || 'pending',
      shares: 0,
      organizer: {
        id: eventData.organizer.id,
        name: eventData.organizer.name,
        imageUrl: eventData.organizer.imageUrl || null,
      }
    };

    await setDoc(doc(eventsRef, eventId), eventToCreate);
    
    return {
      ...eventToCreate,
      id: eventId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    } as Event;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

// Update existing event
export const updateEvent = async (eventId: string, eventData: Partial<Event>): Promise<void> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, {
      ...eventData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};

// Delete event
export const deleteEvent = async (eventId: string): Promise<void> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await deleteDoc(eventRef);
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};

// Update event share count
export const shareEvent = async (eventId: string): Promise<void> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, {
      shares: increment(1)
    });
  } catch (error) {
    console.error('Error updating share count:', error);
    throw error;
  }
};