import { Event } from '../types/event';

export const fallbackEvents: Event[] = [
  {
    id: 'fallback-1',
    title: 'Amsterdam Light Festival',
    description: 'Experience the magical light art installations along Amsterdam\'s historic canals during the annual Light Festival.',
    imageUrl: 'https://images.unsplash.com/photo-1513622470522-26c3c8a854bc',
    startDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date(Date.now() + 8 * 24 * 60 * 60 * 1000).toISOString(),
    location: {
      name: 'Amsterdam Canals',
      address: 'Herengracht, 1017 Amsterdam',
      latitude: '52.366892',
      longitude: '4.895435'
    },
    category: 'Arts',
    price: {
      min: 21,
      max: 25
    },
    maxAttendees: '1000',
    organizer: {
      id: 'fallback-organizer-1',
      name: 'Amsterdam Light Festival Foundation',
      imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e'
    },
    status: 'approved',
    createdAt: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
    updatedAt: new Date(Date.now() - 25 * 24 * 60 * 60 * 1000).toISOString(),
    source: 'direct',
    shares: 245
  },
  {
    id: 'fallback-2',
    title: 'Dutch Food Festival at Westerpark',
    description: 'Taste traditional Dutch cuisine and modern Amsterdam street food at this culinary celebration.',
    imageUrl: 'https://images.unsplash.com/photo-1504674900247-0877df9cc836',
    startDate: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000).toISOString(),
    location: {
      name: 'Westerpark',
      address: 'Haarlemmerweg 4, 1014 BE Amsterdam',
      latitude: '52.386406',
      longitude: '4.877532'
    },
    category: 'Food & Drink',
    price: {
      min: 5,
      max: 35
    },
    maxAttendees: '2000',
    organizer: {
      id: 'fallback-organizer-2',
      name: 'Amsterdam Food Collective',
      imageUrl: 'https://images.unsplash.com/photo-1577219491135-ce391730fb2c'
    },
    status: 'approved',
    createdAt: new Date(Date.now() - 20 * 24 * 60 * 60 * 1000).toISOString(),
    updatedAt: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000).toISOString(),
    source: 'direct',
    shares: 178
  },
  {
    id: 'fallback-3',
    title: 'Amsterdam Innovation Summit',
    description: 'Join leading tech experts and startups from the Amsterdam ecosystem for a day of innovation and networking.',
    imageUrl: 'https://images.unsplash.com/photo-1540575467063-178a50c2df87',
    startDate: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000).toISOString(),
    location: {
      name: 'Startup Village Amsterdam',
      address: 'Science Park 608, 1098 XH Amsterdam',
      latitude: '52.356133',
      longitude: '4.954411'
    },
    category: 'Technology',
    price: {
      min: 49,
      max: 149
    },
    maxAttendees: '500',
    organizer: {
      id: 'fallback-organizer-3',
      name: 'Amsterdam Tech Hub',
      imageUrl: 'https://images.unsplash.com/photo-1556157382-97eda2f9e2bf'
    },
    status: 'approved',
    createdAt: new Date(Date.now() - 40 * 24 * 60 * 60 * 1000).toISOString(),
    updatedAt: new Date(Date.now() - 35 * 24 * 60 * 60 * 1000).toISOString(),
    source: 'direct',
    shares: 324
  }
];