export const config = {
  firebase: {
    apiKey: "AIzaSyBVHLc0L5YG5FY3-3F5q7ogxmNhf-4XPTg",
    authDomain: "thrilldash.firebaseapp.com",
    projectId: "thrilldash",
    storageBucket: "thrilldash.appspot.com",
    messagingSenderId: "1041124252676",
    appId: "1:1041124252676:web:6305415161c60d1950a951",
    measurementId: "G-D14QH8F9Q5"
  },
  recaptcha: {
    siteKey: "6Ld-CXcqAAAAAHMyCdV1ImB6eOHwpgAptoF4wgt1"
  },
  emulators: {
    enabled: import.meta.env.DEV,
    host: 'localhost',
    ports: {
      auth: 9099,
      firestore: 8080,
      storage: 9199
    }
  }
};