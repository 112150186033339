export const GOOGLE_MAPS_CONFIG = {
  apiKey: 'AIzaSyBj7_Hdm14bG71X9FJ8lVvHiR7volVHuHU',
  signingSecret: 'QNh9CSi6-tG8VOUHkX5DxrLdryo=',
  options: {
    libraries: ['places'] as ['places'],
    componentRestrictions: { country: 'nl' },
    fields: [
      'address_components',
      'formatted_address',
      'geometry',
      'name',
      'place_id'
    ],
    types: ['geocode', 'establishment']
  }
};