import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Search, Edit2, Trash2, Eye, Calendar, Clock, Users, AlertCircle, History } from 'lucide-react';
import { getUserEvents, deleteEvent } from '../services/events';
import { useAuthStore } from '../store/authStore';
import DeleteEventModal from '../components/DeleteEventModal';
import { Event } from '../types/event';
import { useTranslation } from 'react-i18next';
import { useDashboardFilters } from '../hooks/useDashboardFilters';
import { useAuth } from '../contexts/AuthContext';

export default function HostDashboardPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { loading } = useAuth();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<'published' | 'pending' | 'rejected' | 'draft' | 'past'>('published');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!loading && (!user || user.role !== 'host')) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  // Load all event types simultaneously
  const { data: events = [], isLoading } = useQuery({
    queryKey: ['user-events', user?.id],
    queryFn: () => getUserEvents(user!.id),
    enabled: !!user,
    staleTime: 1000 * 30, // Consider data stale after 30 seconds
    refetchInterval: 1000 * 60, // Refetch every minute
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // Filter events using custom hook
  const { stats, filteredEvents } = useDashboardFilters({
    events,
    searchQuery,
    activeTab,
  });

  // Delete mutation with optimistic updates
  const deleteMutation = useMutation({
    mutationFn: deleteEvent,
    onMutate: async (eventId) => {
      await queryClient.cancelQueries({ queryKey: ['user-events'] });
      const previousEvents = queryClient.getQueryData(['user-events', user?.id]);
      queryClient.setQueryData(['user-events', user?.id], (old: Event[] = []) => 
        old.filter(event => event.id !== eventId)
      );
      return { previousEvents };
    },
    onError: (err, variables, context) => {
      if (context?.previousEvents) {
        queryClient.setQueryData(['user-events', user?.id], context.previousEvents);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user-events'] });
      setDeleteModalOpen(false);
      setSelectedEvent(null);
    },
  });

  const handleDelete = async () => {
    if (!selectedEvent) return;
    try {
      setIsDeleting(true);
      await deleteMutation.mutateAsync(selectedEvent.id);
    } catch (error) {
      console.error('Error deleting event:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (loading || !user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved': return 'bg-green-100 text-green-800';
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'rejected': return 'bg-red-100 text-red-800';
      case 'draft': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 py-4 sm:py-8">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div>
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900">{t('dashboard.title')}</h1>
          <p className="mt-1 text-sm text-gray-700">
            {t('dashboard.subtitle')}
          </p>
        </div>
        <button
          onClick={() => navigate('/create-event')}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 w-full sm:w-auto justify-center"
        >
          {t('dashboard.create_event')}
        </button>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4 mb-6">
        {[
          { id: 'published', label: t('dashboard.stats.published'), count: stats.published, icon: Calendar },
          { id: 'pending', label: t('dashboard.stats.pending'), count: stats.pending, icon: Clock },
          { id: 'rejected', label: t('dashboard.stats.rejected'), count: stats.rejected, icon: AlertCircle },
          { id: 'draft', label: t('dashboard.stats.drafts'), count: stats.draft, icon: Edit2 },
          { id: 'past', label: t('dashboard.stats.past'), count: stats.past, icon: History }
        ].map(({ id, label, count, icon: Icon }) => (
          <button
            key={id}
            onClick={() => setActiveTab(id as any)}
            className={`relative rounded-lg p-3 sm:p-4 bg-white shadow-sm border ${
              activeTab === id ? 'border-brand-500 ring-1 ring-brand-500' : 'border-gray-200'
            }`}
          >
            <div className="flex flex-col sm:flex-row sm:items-center gap-2">
              <div className="flex-shrink-0">
                <Icon className={`h-5 w-5 ${
                  activeTab === id ? 'text-brand-600' : 'text-gray-400'
                }`} />
              </div>
              <div className="min-w-0">
                <p className="text-xs sm:text-sm font-medium text-gray-900 truncate">{label}</p>
                <p className="text-lg sm:text-xl font-semibold text-gray-900">{count}</p>
              </div>
            </div>
          </button>
        ))}
      </div>

      {/* Search */}
      <div className="mb-6">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('dashboard.search.placeholder')}
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500 text-sm"
          />
        </div>
      </div>

      {/* Events List */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
        </div>
      ) : filteredEvents.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-gray-500">No events found</p>
          <p className="mt-2 text-sm text-gray-400">
            {searchQuery
              ? 'Try adjusting your search terms'
              : `No ${activeTab} events found`}
          </p>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg divide-y divide-gray-200">
          {filteredEvents.map((event) => (
            <div key={event.id} className="p-4 sm:p-6">
              <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                {/* Event Image and Info */}
                <div className="flex items-start space-x-4 flex-1 min-w-0">
                  <div className="flex-shrink-0 w-20 h-20 sm:w-24 sm:h-24">
                    <img
                      src={event.imageUrl || 'https://via.placeholder.com/150'}
                      alt=""
                      className="w-full h-full rounded-lg object-cover"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <h2 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                        {event.title}
                      </h2>
                      <span className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        getStatusColor(event.status)
                      }`}>
                        {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                      </span>
                    </div>
                    <div className="mt-2 flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-gray-500">
                      <div className="flex items-center">
                        <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4" />
                        <span>{format(new Date(event.startDate), 'PPP')}</span>
                      </div>
                      <div className="flex items-center">
                        <Users className="flex-shrink-0 mr-1.5 h-4 w-4" />
                        <span>{event.maxAttendees || 'Unlimited'}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex items-center justify-end gap-2 sm:gap-4 mt-4 sm:mt-0">
                  <button
                    onClick={() => navigate(`/event/${event.id}`)}
                    className="inline-flex items-center p-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  >
                    <Eye className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">View</span>
                  </button>
                  <button
                    onClick={() => navigate(`/event/${event.id}/edit`)}
                    className="inline-flex items-center p-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  >
                    <Edit2 className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">Edit</span>
                  </button>
                  <button
                    onClick={() => {
                      setSelectedEvent(event);
                      setDeleteModalOpen(true);
                    }}
                    className="inline-flex items-center p-2 border border-gray-300 rounded-md text-sm font-medium text-red-600 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <Trash2 className="h-4 w-4 sm:mr-2" />
                    <span className="hidden sm:inline">Delete</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <DeleteEventModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedEvent(null);
        }}
        onConfirm={handleDelete}
        eventTitle={selectedEvent?.title || ''}
        isLoading={isDeleting}
      />
    </div>
  );
}