import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Search, Grid, List, MapPin, Filter, Calendar, History } from 'lucide-react';
import { searchEvents } from '../services/events';
import { useEventStore } from '../store/eventStore';
import { useEventFilters } from '../hooks/useEventFilters';
import EventList from '../components/EventList';
import EventMap from '../components/EventMap';
import SearchBar from '../components/SearchBar';
import LocationSearchBox from '../components/LocationSearchBox';
import GoogleMapsLoader from '../components/GoogleMapsLoader';
import { useTranslation } from 'react-i18next';
import { EVENT_CATEGORIES } from '../constants/categories';
import { TimeRangeType } from '../utils/dateRanges';

export default function EventsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<'grid' | 'list' | 'map'>('grid');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<'date' | 'popularity'>('date');
  const [searchLocation, setSearchLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [searchRadius, setSearchRadius] = useState(10);
  const [activeTimeFilter, setActiveTimeFilter] = useState<TimeRangeType>('all');
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Handle search query from homepage
  useEffect(() => {
    const state = location.state as { fromSearch?: boolean; searchQuery?: string } | null;
    if (state?.fromSearch && state.searchQuery) {
      setSearchQuery(state.searchQuery);
      // Clear the location state to prevent re-applying the search on navigation
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  // Fetch all events
  const { data: events = [], isLoading } = useQuery({
    queryKey: ['events'],
    queryFn: () => searchEvents({ filters: { status: 'approved' } }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Apply filters
  const filteredEvents = useEventFilters({
    events,
    searchQuery,
    selectedCategories,
    activeTimeFilter,
    sortBy,
    searchLocation: searchLocation ? { ...searchLocation, radius: searchRadius } : null,
    showPastEvents
  });

  const handleLocationSelect = (location: { lat: number; lng: number }) => {
    setSearchLocation(location);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSearchLocation(null);
    setActiveTimeFilter('all');
    setShowPastEvents(false);
    setSearchQuery('');
  };

  const handleTimeFilter = (type: TimeRangeType) => {
    if (type === 'past') {
      setShowPastEvents(!showPastEvents);
      setActiveTimeFilter('all');
    } else {
      setActiveTimeFilter(type === activeTimeFilter ? 'all' : type);
      setShowPastEvents(false);
    }
  };

  const hasActiveFilters = selectedCategories.length > 0 || 
                          searchLocation !== null || 
                          activeTimeFilter !== 'all' || 
                          showPastEvents ||
                          searchQuery !== '';

  const mapCenter = searchLocation || { lat: 52.3676, lng: 4.9041 }; // Amsterdam as default

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b sticky top-0 z-20">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col gap-4 mb-4">
            {/* Title and View Controls */}
            <div className="flex items-center justify-between">
              <h1 className="text-xl sm:text-2xl font-bold text-gray-900">
                {t('events.discover')}
              </h1>
              <div className="flex items-center space-x-1 sm:space-x-2">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-1.5 sm:p-2 rounded-md ${
                    viewMode === 'grid' 
                      ? 'bg-brand-100 text-brand-600' 
                      : 'text-gray-500 hover:bg-gray-100'
                  }`}
                >
                  <Grid className="h-4 w-4 sm:h-5 sm:w-5" />
                </button>
                <button
                  onClick={() => setViewMode('list')}
                  className={`p-1.5 sm:p-2 rounded-md ${
                    viewMode === 'list' 
                      ? 'bg-brand-100 text-brand-600' 
                      : 'text-gray-500 hover:bg-gray-100'
                  }`}
                >
                  <List className="h-4 w-4 sm:h-5 sm:w-5" />
                </button>
                <button
                  onClick={() => setViewMode('map')}
                  className={`p-1.5 sm:p-2 rounded-md ${
                    viewMode === 'map' 
                      ? 'bg-brand-100 text-brand-600' 
                      : 'text-gray-500 hover:bg-gray-100'
                  }`}
                >
                  <MapPin className="h-4 w-4 sm:h-5 sm:w-5" />
                </button>
              </div>
            </div>

            {/* Search and Controls */}
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
              <div className="flex-1">
                <SearchBar value={searchQuery} onChange={setSearchQuery} />
              </div>
              <div className="flex items-center gap-2">
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value as 'date' | 'popularity')}
                  className="block w-24 sm:w-40 text-sm rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
                >
                  <option value="date">{t('events.sort_date')}</option>
                  <option value="popularity">{t('events.sort_popularity')}</option>
                </select>
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className={`flex items-center px-2 sm:px-4 py-2 rounded-md text-sm font-medium whitespace-nowrap ${
                    showFilters || hasActiveFilters
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  <Filter className="h-4 w-4 sm:mr-2" />
                  <span className="hidden sm:inline">{t('events.filters')}</span>
                  {hasActiveFilters && (
                    <span className="ml-1 sm:ml-2 bg-brand-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                      {(selectedCategories.length > 0 ? 1 : 0) + 
                       (searchLocation ? 1 : 0) +
                       (activeTimeFilter !== 'all' ? 1 : 0) +
                       (showPastEvents ? 1 : 0)}
                    </span>
                  )}
                </button>
              </div>
            </div>

            {/* Quick Filters */}
            <div className="overflow-x-auto -mx-2 px-2">
              <div className="flex gap-1.5 sm:gap-2 pb-2">
                <button
                  onClick={() => handleTimeFilter('all')}
                  className={`flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                    activeTimeFilter === 'all' && !showPastEvents
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                  {t('events.all_events')}
                </button>
                <button
                  onClick={() => handleTimeFilter('today')}
                  className={`flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                    activeTimeFilter === 'today'
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                  {t('events.today')}
                </button>
                <button
                  onClick={() => handleTimeFilter('weekend')}
                  className={`flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                    activeTimeFilter === 'weekend'
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                  {t('events.this_weekend')}
                </button>
                <button
                  onClick={() => handleTimeFilter('week')}
                  className={`flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                    activeTimeFilter === 'week'
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                  {t('events.next_7_days')}
                </button>
                <button
                  onClick={() => handleTimeFilter('month')}
                  className={`flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                    activeTimeFilter === 'month'
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                  {t('events.this_month')}
                </button>
                <button
                  onClick={() => handleTimeFilter('past')}
                  className={`flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                    showPastEvents
                      ? 'bg-brand-100 text-brand-600'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  <History className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                  {t('events.past_events')}
                </button>
              </div>
            </div>

            {/* Filters Panel */}
            {showFilters && (
              <div className="mt-4 p-4 border-t">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Categories */}
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">{t('events.categories')}</h3>
                    <div className="flex flex-wrap gap-2">
                      {EVENT_CATEGORIES.map((category) => (
                        <button
                          key={category}
                          onClick={() => {
                            setSelectedCategories(prev => 
                              prev.includes(category)
                                ? prev.filter(c => c !== category)
                                : [...prev, category]
                            );
                          }}
                          className={`px-3 py-1 rounded-full text-sm ${
                            selectedCategories.includes(category)
                              ? 'bg-brand-100 text-brand-600'
                              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                          }`}
                        >
                          {t(`categories.${category.toLowerCase().replace(' & ', '_')}`)}
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Location Search */}
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">{t('events.location')}</h3>
                    <GoogleMapsLoader>
                      <LocationSearchBox 
                        onSelect={handleLocationSelect}
                        placeholder={t('events.search_location')}
                      />
                    </GoogleMapsLoader>
                    {searchLocation && (
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          {t('events.search_radius')}: {searchRadius}km
                        </label>
                        <input
                          type="range"
                          min="1"
                          max="50"
                          value={searchRadius}
                          onChange={(e) => setSearchRadius(Number(e.target.value))}
                          className="w-full"
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Clear Filters */}
                {hasActiveFilters && (
                  <button
                    onClick={clearFilters}
                    className="mt-4 text-sm text-brand-600 hover:text-brand-700"
                  >
                    {t('events.clear_filters')}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-96">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
          </div>
        ) : (
          <>
            <div className="mb-6">
              <h2 className="text-lg font-medium text-gray-900">
                {filteredEvents.length} {filteredEvents.length === 1 ? t('events.event') : t('events.events')} {t('events.found')}
              </h2>
              {hasActiveFilters && (
                <div className="mt-2 flex flex-wrap gap-2">
                  {selectedCategories.map((category) => (
                    <span key={category} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-800">
                      {t(`categories.${category.toLowerCase().replace(' & ', '_')}`)}
                    </span>
                  ))}
                  {searchLocation && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-800">
                      <MapPin className="h-3 w-3 mr-1" />
                      {t('events.within')} {searchRadius}km
                    </span>
                  )}
                </div>
              )}
            </div>

            {viewMode === 'map' ? (
              <div className="h-[calc(100vh-16rem)] rounded-lg overflow-hidden border border-gray-200">
                <EventMap
                  events={filteredEvents}
                  center={mapCenter}
                  onEventClick={(event) => navigate(`/event/${event.id}`)}
                  searchRadius={searchRadius}
                  searchCenter={searchLocation}
                />
              </div>
            ) : (
              <EventList
                events={filteredEvents}
                onEventClick={(event) => navigate(`/event/${event.id}`)}
                displayMode={viewMode}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}