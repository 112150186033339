import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Search, Edit2, Trash2, Eye, Calendar, Clock, Users, AlertCircle, Shield } from 'lucide-react';
import { getPendingEvents, moderateEvent, getApprovedEvents, getRejectedEvents, getDraftEvents } from '../services/moderation';
import { useAuthStore } from '../store/authStore';
import { Event } from '../types/event';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';

export default function AdminModerationPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { loading } = useAuth();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<'pending' | 'approved' | 'rejected' | 'draft'>('pending');
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [moderationNotes, setModerationNotes] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!loading && (!user || user.role !== 'admin')) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  // Load all event types simultaneously
  const { data: pendingEvents = [], isLoading: isPendingLoading } = useQuery({
    queryKey: ['pending-events'],
    queryFn: () => getPendingEvents(),
    enabled: !!user && user.role === 'admin',
    refetchInterval: 30000, // Refetch every 30 seconds
  });

  const { data: approvedEvents = [], isLoading: isApprovedLoading } = useQuery({
    queryKey: ['approved-events'],
    queryFn: () => getApprovedEvents(50),
    enabled: !!user && user.role === 'admin',
  });

  const { data: rejectedEvents = [], isLoading: isRejectedLoading } = useQuery({
    queryKey: ['rejected-events'],
    queryFn: () => getRejectedEvents(50),
    enabled: !!user && user.role === 'admin',
  });

  const { data: draftEvents = [], isLoading: isDraftLoading } = useQuery({
    queryKey: ['draft-events'],
    queryFn: () => getDraftEvents(50),
    enabled: !!user && user.role === 'admin',
  });

  const moderateMutation = useMutation({
    mutationFn: ({ eventId, status }: { eventId: string; status: 'approved' | 'rejected' | 'pending' | 'draft' }) =>
      moderateEvent(
        eventId,
        status,
        {
          id: user!.id,
          name: user!.displayName,
          photoURL: user!.photoURL
        },
        moderationNotes
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pending-events'] });
      queryClient.invalidateQueries({ queryKey: ['approved-events'] });
      queryClient.invalidateQueries({ queryKey: ['rejected-events'] });
      queryClient.invalidateQueries({ queryKey: ['draft-events'] });
      setSelectedEvent(null);
      setModerationNotes('');
      setError(null);
    },
    onError: (error: Error) => {
      setError(error.message);
    }
  });

  if (loading || !user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
      </div>
    );
  }

  const isLoading = isPendingLoading || isApprovedLoading || isRejectedLoading || isDraftLoading;

  // Calculate stats from loaded data
  const stats = {
    pending: pendingEvents.length,
    approved: approvedEvents.length,
    rejected: rejectedEvents.length,
    draft: draftEvents.length
  };

  // Get current events based on active tab
  const getCurrentEvents = () => {
    switch (activeTab) {
      case 'pending':
        return pendingEvents;
      case 'approved':
        return approvedEvents;
      case 'rejected':
        return rejectedEvents;
      case 'draft':
        return draftEvents;
      default:
        return [];
    }
  };

  // Filter events based on search query
  const filteredEvents = getCurrentEvents().filter(event => 
    event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    event.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved': return 'bg-green-100 text-green-800';
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'rejected': return 'bg-red-100 text-red-800';
      case 'draft': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{t('moderation.title')}</h1>
          <p className="mt-2 text-sm text-gray-700">
            {t('moderation.subtitle')}
          </p>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {[
          { id: 'pending', label: t('moderation.stats.pending_review'), count: stats.pending, icon: Clock },
          { id: 'approved', label: t('moderation.stats.approved'), count: stats.approved, icon: Calendar },
          { id: 'rejected', label: t('moderation.stats.rejected'), count: stats.rejected, icon: AlertCircle },
          { id: 'draft', label: t('moderation.stats.drafts'), count: stats.draft, icon: Edit2 }
        ].map(({ id, label, count, icon: Icon }) => (
          <button
            key={id}
            onClick={() => setActiveTab(id as any)}
            className={`relative rounded-lg p-6 bg-white shadow ${
              activeTab === id ? 'ring-2 ring-indigo-500' : ''
            }`}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Icon className={`h-6 w-6 ${
                  activeTab === id ? 'text-indigo-600' : 'text-gray-400'
                }`} />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-gray-900">{label}</h3>
                <p className="mt-1 text-3xl font-semibold text-gray-900">{count}</p>
              </div>
            </div>
          </button>
        ))}
      </div>

      {/* Search */}
      <div className="mt-8">
        <div className="relative flex-1 max-w-lg">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('moderation.search.placeholder')}
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      {/* Event List and Details */}
      <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Event List */}
        <div className="lg:col-span-2">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600" />
            </div>
          ) : filteredEvents.length === 0 ? (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <p className="text-gray-500">No events found</p>
              <p className="mt-2 text-sm text-gray-400">
                {searchQuery
                  ? 'Try adjusting your search terms'
                  : `No ${activeTab} events to review`}
              </p>
            </div>
          ) : (
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {filteredEvents.map((event) => (
                  <li
                    key={event.id}
                    onClick={() => setSelectedEvent(event)}
                    className={`px-6 py-4 cursor-pointer hover:bg-gray-50 ${
                      selectedEvent?.id === event.id ? 'bg-indigo-50' : ''
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center min-w-0">
                        <div className="flex-shrink-0 h-16 w-16">
                          <img
                            src={event.imageUrl || 'https://via.placeholder.com/150'}
                            alt=""
                            className="h-16 w-16 rounded-lg object-cover"
                          />
                        </div>
                        <div className="ml-4">
                          <div className="flex items-center justify-between">
                            <h2 className="text-lg font-medium text-gray-900 truncate">
                              {event.title}
                            </h2>
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              getStatusColor(event.status)
                            }`}>
                              {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                            </span>
                          </div>
                          <div className="mt-2 flex items-center text-sm text-gray-500">
                            <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4" />
                            <span>{format(new Date(event.startDate), 'PPP')}</span>
                            <Users className="flex-shrink-0 mx-1.5 h-4 w-4" />
                            <span>{event.maxAttendees || 'Unlimited'} attendees</span>
                          </div>
                          <div className="mt-2 flex items-center text-sm text-gray-500">
                            <Shield className="flex-shrink-0 mr-1.5 h-4 w-4" />
                            <span>By {event.organizer.name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="ml-6 flex items-center space-x-4">
                        <Link
                          to={`/event/${event.id}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="text-gray-400 hover:text-gray-500"
                          title="View Event"
                        >
                          <Eye className="h-5 w-5" />
                        </Link>
                        <Link
                          to={`/event/${event.id}/edit`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="text-gray-400 hover:text-gray-500"
                          title="Edit Event"
                        >
                          <Edit2 className="h-5 w-5" />
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Event Details */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-lg shadow p-6 sticky top-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              {t('moderation.details.title')}
            </h2>
            {selectedEvent ? (
              <div className="space-y-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-700">Event Title</h3>
                  <Link
                    to={`/event/${selectedEvent.id}`}
                    className="mt-1 text-sm text-brand-600 hover:text-brand-800"
                  >
                    {selectedEvent.title}
                  </Link>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-700">{t('moderation.details.description')}</h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {selectedEvent.description}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-700">{t('moderation.details.location')}</h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {selectedEvent.location.name}
                    <br />
                    {selectedEvent.location.address}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-700">
                    {t('moderation.details.moderation_notes')}
                  </h3>
                  <textarea
                    value={moderationNotes}
                    onChange={(e) => setModerationNotes(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    rows={3}
                    placeholder={t('moderation.details.notes_placeholder')}
                  />
                </div>

                {selectedEvent.moderatedBy && (
                  <div className="pt-4 border-t">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        {selectedEvent.moderatedBy.photoURL ? (
                          <img
                            src={selectedEvent.moderatedBy.photoURL}
                            alt={selectedEvent.moderatedBy.name}
                            className="h-6 w-6 rounded-full"
                          />
                        ) : (
                          <div className="h-6 w-6 rounded-full bg-gray-200 flex items-center justify-center">
                            <span className="text-xs font-medium text-gray-500">
                              {selectedEvent.moderatedBy.name[0]}
                            </span>
                          </div>
                        )}
                        <span className="text-sm text-gray-600">
                          {t('moderation.details.last_moderated')} {selectedEvent.moderatedBy.name}
                        </span>
                      </div>
                      {selectedEvent.moderatedAt && (
                        <p className="text-sm text-gray-500">
                          {t('moderation.details.on_date')} {format(new Date(selectedEvent.moderatedAt), 'PPP p')}
                        </p>
                      )}
                      {selectedEvent.moderationNotes && (
                        <div className="mt-2">
                          <h4 className="text-sm font-medium text-gray-700">{t('moderation.details.previous_notes')}</h4>
                          <p className="mt-1 text-sm text-gray-600">{selectedEvent.moderationNotes}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="flex flex-wrap gap-2 pt-4">
                  <button
                    onClick={() => moderateMutation.mutate({ 
                      eventId: selectedEvent.id, 
                      status: 'approved' 
                    })}
                    disabled={moderateMutation.isPending}
                    className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
                  >
                    {t('moderation.actions.approve')}
                  </button>
                  <button
                    onClick={() => moderateMutation.mutate({ 
                      eventId: selectedEvent.id, 
                      status: 'rejected' 
                    })}
                    disabled={moderateMutation.isPending}
                    className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 disabled:opacity-50"
                  >
                    {t('moderation.actions.reject')}
                  </button>
                  <button
                    onClick={() => moderateMutation.mutate({ 
                      eventId: selectedEvent.id, 
                      status: 'pending' 
                    })}
                    disabled={moderateMutation.isPending}
                    className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 disabled:opacity-50"
                  >
                    {t('moderation.actions.set_pending')}
                  </button>
                  <button
                    onClick={() => moderateMutation.mutate({ 
                      eventId: selectedEvent.id, 
                      status: 'draft' 
                    })}
                    disabled={moderateMutation.isPending}
                    className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 disabled:opacity-50"
                  >
                    {t('moderation.actions.set_draft')}
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-gray-50 rounded-lg p-6 text-center">
                <Shield className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">{t('moderation.details.no_event_selected')}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t('moderation.details.select_message')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}