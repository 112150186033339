import { create } from 'zustand';
import { Event } from '../types/event';
import { EventCategory } from '../constants/categories';

interface EventFilters {
  categories: EventCategory[];
  dateRange?: { start: Date; end: Date };
  priceRange?: { min: number; max: number };
  location?: { lat: number; lng: number; radius: number };
  showPastEvents?: boolean;
  sortBy?: 'date' | 'popularity';
}

interface EventStore {
  events: Event[];
  filteredEvents: Event[];
  filters: EventFilters;
  viewMode: 'map' | 'list';
  searchQuery: string;
  isLoading: boolean;
  setEvents: (events: Event[]) => void;
  setFilters: (filters: Partial<EventFilters>) => void;
  setViewMode: (mode: 'map' | 'list') => void;
  setSearchQuery: (query: string) => void;
  performSearch: () => void;
}

export const useEventStore = create<EventStore>((set, get) => ({
  events: [],
  filteredEvents: [],
  filters: {
    categories: [],
    showPastEvents: false,
  },
  viewMode: 'list',
  searchQuery: '',
  isLoading: false,

  setEvents: (events) => {
    set({ events });
    get().performSearch();
  },

  setFilters: (newFilters) => {
    set((state) => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
      isLoading: true,
    }));
    get().performSearch();
  },

  setViewMode: (mode) => set({ viewMode: mode }),

  setSearchQuery: (query) => {
    set({ searchQuery: query, isLoading: true });
    get().performSearch();
  },

  performSearch: () => {
    const { events, filters, searchQuery } = get();
    
    try {
      let filtered = [...events];

      // Apply search query filter
      if (searchQuery) {
        const searchTerms = searchQuery.toLowerCase().split(' ').filter(term => term.length > 0);
        filtered = filtered.filter(event => {
          const searchableText = `
            ${event.title} 
            ${event.description} 
            ${event.location.name} 
            ${event.location.address}
            ${event.categories ? event.categories.join(' ') : event.category}
          `.toLowerCase();
          
          // Match any of the search terms in any of the searchable fields
          return searchTerms.some(term => 
            event.title.toLowerCase().includes(term) ||
            event.description.toLowerCase().includes(term) ||
            event.location.name.toLowerCase().includes(term) ||
            event.location.address.toLowerCase().includes(term) ||
            (event.categories || [event.category]).some(cat => 
              cat.toLowerCase().includes(term)
            )
          );
        });

        // Sort results by relevance
        filtered.sort((a, b) => {
          const aScore = searchTerms.reduce((score, term) => {
            // Higher weight for title matches
            if (a.title.toLowerCase().includes(term)) score += 3;
            // Medium weight for location and category matches
            if (a.location.name.toLowerCase().includes(term)) score += 2;
            if ((a.categories || [a.category]).some(cat => cat.toLowerCase().includes(term))) score += 2;
            // Lower weight for description matches
            if (a.description.toLowerCase().includes(term)) score += 1;
            return score;
          }, 0);

          const bScore = searchTerms.reduce((score, term) => {
            if (b.title.toLowerCase().includes(term)) score += 3;
            if (b.location.name.toLowerCase().includes(term)) score += 2;
            if ((b.categories || [b.category]).some(cat => cat.toLowerCase().includes(term))) score += 2;
            if (b.description.toLowerCase().includes(term)) score += 1;
            return score;
          }, 0);

          return bScore - aScore;
        });
      }

      // Apply category filter
      if (filters.categories.length > 0) {
        filtered = filtered.filter(event => {
          const eventCategories = Array.isArray(event.categories) 
            ? event.categories 
            : [event.category];
            
          return filters.categories.some(filterCategory =>
            eventCategories.includes(filterCategory)
          );
        });
      }

      // Apply date range filter
      if (filters.dateRange) {
        filtered = filtered.filter(event => {
          const eventDate = new Date(event.startDate);
          return eventDate >= filters.dateRange!.start && 
                 eventDate <= filters.dateRange!.end;
        });
      }

      // Filter past events
      if (!filters.showPastEvents) {
        const now = new Date();
        filtered = filtered.filter(event => {
          const eventEndDate = new Date(event.endDate);
          return eventEndDate >= now;
        });
      }

      // Apply price range filter
      if (filters.priceRange) {
        filtered = filtered.filter(event => {
          if (!event.price) return filters.priceRange!.min === 0;
          return event.price.min >= filters.priceRange!.min && 
                 event.price.max <= filters.priceRange!.max;
        });
      }

      // Apply location filter
      if (filters.location) {
        filtered = filtered.filter(event => {
          const distance = calculateDistance(
            filters.location!.lat,
            filters.location!.lng,
            parseFloat(event.location.latitude),
            parseFloat(event.location.longitude)
          );
          return distance <= filters.location!.radius;
        });
      }

      // Sort by date or popularity
      if (filters.sortBy === 'popularity') {
        filtered.sort((a, b) => (b.shares || 0) - (a.shares || 0));
      } else {
        filtered.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      }

      set({ 
        filteredEvents: filtered,
        isLoading: false 
      });
    } catch (error) {
      console.error('Error applying filters:', error);
      set({ 
        filteredEvents: [],
        isLoading: false 
      });
    }
  },
}));

// Helper function to calculate distance between coordinates using Haversine formula
function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}

function toRad(value: number): number {
  return value * Math.PI / 180;
}