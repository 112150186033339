import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  deleteDoc,
  serverTimestamp,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment
} from 'firebase/firestore';
import { db } from './firebase';
import { Event } from '../types/event';
import { getEventById } from './events';

export const getUserCollections = async (userId: string): Promise<{
  saved: Event[];
  liked: Event[];
}> => {
  try {
    const collectionsRef = collection(db, 'collections');
    const savedQuery = query(
      collectionsRef,
      where('userId', '==', userId),
      where('type', '==', 'saved')
    );
    const likedQuery = query(
      collectionsRef,
      where('userId', '==', userId),
      where('type', '==', 'liked')
    );

    const [savedSnapshot, likedSnapshot] = await Promise.all([
      getDocs(savedQuery),
      getDocs(likedQuery)
    ]);

    const savedEvents = await Promise.all(
      savedSnapshot.docs.map(doc => getEventById(doc.data().eventId))
    );
    const likedEvents = await Promise.all(
      likedSnapshot.docs.map(doc => getEventById(doc.data().eventId))
    );

    return {
      saved: savedEvents.filter(Boolean) as Event[],
      liked: likedEvents.filter(Boolean) as Event[]
    };
  } catch (error) {
    console.error('Error getting user collections:', error);
    return { saved: [], liked: [] };
  }
};

export const toggleEventInCollection = async (
  userId: string,
  eventId: string,
  type: 'saved' | 'liked'
): Promise<boolean> => {
  try {
    const collectionsRef = collection(db, 'collections');
    const q = query(
      collectionsRef,
      where('userId', '==', userId),
      where('eventId', '==', eventId),
      where('type', '==', type)
    );

    const snapshot = await getDocs(q);
    const analyticsRef = doc(db, 'analytics', `event_${eventId}`);

    if (snapshot.empty) {
      // Add to collection
      await addDoc(collectionsRef, {
        userId,
        eventId,
        type,
        createdAt: serverTimestamp()
      });

      // Update user's collections array
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        [`collections.${type}`]: arrayUnion(eventId)
      });

      // Update analytics
      await updateDoc(analyticsRef, {
        [type === 'liked' ? 'likes' : 'saves']: increment(1),
        updatedAt: serverTimestamp()
      });

      return true;
    } else {
      // Remove from collection
      await deleteDoc(snapshot.docs[0].ref);

      // Update user's collections array
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        [`collections.${type}`]: arrayRemove(eventId)
      });

      // Update analytics
      await updateDoc(analyticsRef, {
        [type === 'liked' ? 'likes' : 'saves']: increment(-1),
        updatedAt: serverTimestamp()
      });

      return false;
    }
  } catch (error) {
    console.error('Error toggling event in collection:', error);
    throw error;
  }
};

export const isEventInCollection = async (
  userId: string,
  eventId: string,
  type: 'saved' | 'liked'
): Promise<boolean> => {
  try {
    const collectionsRef = collection(db, 'collections');
    const q = query(
      collectionsRef,
      where('userId', '==', userId),
      where('eventId', '==', eventId),
      where('type', '==', type)
    );

    const snapshot = await getDocs(q);
    return !snapshot.empty;
  } catch (error) {
    console.error('Error checking collection status:', error);
    return false;
  }
};