import React, { useState } from 'react';
import { LogIn, LogOut, User, ChevronDown, Shield, Upload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { signOut } from '../services/auth';
import AuthModal from './AuthModal';

interface AuthButtonProps {
  isHomePage?: boolean;
}

export default function AuthButton({ isHomePage = false }: AuthButtonProps) {
  const navigate = useNavigate();
  const { user, setUser } = useAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');

  const handleSignOut = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await signOut();
      setUser(null);
      navigate('/');
    } catch (error: any) {
      setError('Failed to sign out. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (user) {
    const isApprovedModerator = user.role === 'moderator' && user.approved;
    
    return (
      <div className="relative">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className={`flex items-center space-x-3 rounded-full pl-2 pr-4 py-1 transition-colors duration-200 ${
            isHomePage
              ? 'bg-white/10 hover:bg-white/20 text-white'
              : 'bg-white border border-gray-200 hover:bg-gray-50 text-gray-900'
          }`}
        >
          {user.photoURL ? (
            <img
              src={user.photoURL}
              alt={user.displayName}
              className="h-8 w-8 rounded-full border-2 border-gray-200"
            />
          ) : (
            <div className="h-8 w-8 rounded-full bg-gray-100 border-2 border-gray-200 flex items-center justify-center">
              <User className="h-4 w-4 text-gray-600" />
            </div>
          )}
          <div className="flex items-center">
            <div className="flex flex-col items-start">
              <span className="text-sm font-medium">
                {user.displayName}
              </span>
              <div className="flex items-center">
                <span className={`text-xs capitalize ${isHomePage ? 'text-gray-200' : 'text-gray-500'}`}>
                  {user.role}
                </span>
                {user.role === 'moderator' && !user.approved && (
                  <span className="ml-1 px-1.5 py-0.5 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                    Pending
                  </span>
                )}
              </div>
            </div>
            <ChevronDown className={`h-4 w-4 ml-2 ${isHomePage ? 'text-gray-200' : 'text-gray-400'}`} />
          </div>
        </button>

        {isDropdownOpen && (
          <>
            <div
              className="fixed inset-0 z-30"
              onClick={() => setIsDropdownOpen(false)}
            />
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-40">
              <button
                onClick={() => {
                  navigate('/profile');
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Profile
              </button>
              
              {user.role === 'host' && (
                <button
                  onClick={() => {
                    navigate('/host-dashboard');
                    setIsDropdownOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Event Dashboard
                </button>
              )}

              {isApprovedModerator && (
                <button
                  onClick={() => {
                    navigate('/moderation');
                    setIsDropdownOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Moderation
                </button>
              )}

              {user.role === 'admin' && (
                <>
                  <button
                    onClick={() => {
                      navigate('/admin/moderation');
                      setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Admin Moderation
                  </button>
                  <button
                    onClick={() => {
                      navigate('/admin/moderator-approvals');
                      setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Moderator Approvals
                  </button>
                  <button
                    onClick={() => {
                      navigate('/admin/bulk-upload');
                      setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      <Upload className="h-4 w-4 mr-2" />
                      Bulk Upload Events
                    </div>
                  </button>
                </>
              )}

              <button
                onClick={() => {
                  handleSignOut();
                  setIsDropdownOpen(false);
                }}
                disabled={isLoading}
                className="block w-full text-left px-4 py-2 text-sm text-brand-600 hover:bg-gray-100"
              >
                {isLoading ? 'Signing out...' : 'Sign Out'}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => {
          setAuthMode('signin');
          setIsModalOpen(true);
        }}
        className={`text-sm font-medium ${
          isHomePage ? 'text-white hover:text-white/80' : 'text-gray-900 hover:text-gray-700'
        }`}
      >
        Log in
      </button>
      <button
        onClick={() => {
          setAuthMode('signup');
          setIsModalOpen(true);
        }}
        className="flex items-center space-x-2 px-4 py-2 rounded-full transition-colors duration-200 
          bg-brand-600 text-white hover:bg-brand-700 shadow-sm"
      >
        <LogIn className="h-4 w-4" />
        <span>Sign up</span>
      </button>
      <AuthModal 
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
          setAuthMode('signin');
        }} 
        initialMode={authMode}
      />
    </div>
  );
}