import { startOfDay, endOfDay, addDays, addMonths, isBefore, isAfter, isSameDay, addYears } from 'date-fns';

export type TimeRangeType = 'all' | 'past' | 'today' | 'weekend' | 'week' | 'month' | null;

interface DateRange {
  start: Date;
  end: Date;
}

export function getDateRangeForType(type: TimeRangeType): DateRange | null {
  if (!type) return null;

  const now = new Date();
  const today = startOfDay(now);

  switch (type) {
    case 'all':
      return {
        start: now, // Start from current time
        end: endOfDay(addYears(today, 1)) // Show events up to 1 year ahead
      };

    case 'past':
      return {
        start: new Date(0),
        end: now
      };

    case 'today':
      return {
        start: today,
        end: endOfDay(now)
      };

    case 'weekend':
      const saturday = addDays(today, (6 - today.getDay()) % 7);
      return {
        start: saturday,
        end: endOfDay(addDays(saturday, 1))
      };

    case 'week':
      return {
        start: today,
        end: endOfDay(addDays(today, 7))
      };

    case 'month':
      return {
        start: today,
        end: endOfDay(addMonths(today, 1))
      };

    default:
      return null;
  }
}

export function isEventInDateRange(event: { startDate: string; endDate: string }, range: DateRange): boolean {
  const eventStart = new Date(event.startDate);
  const eventEnd = new Date(event.endDate);
  
  // For past events
  if (range.start.getTime() === 0) {
    return isBefore(eventEnd, range.end);
  }
  
  // For future events, event should:
  // 1. Either start within the range
  // 2. Or be ongoing during the range (started before but ends after range start)
  return (
    (isAfter(eventStart, range.start) && isBefore(eventStart, range.end)) ||
    (isBefore(eventStart, range.end) && isAfter(eventEnd, range.start)) ||
    isSameDay(eventStart, range.start) ||
    isSameDay(eventStart, range.end)
  );
}