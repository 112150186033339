export const EVENT_CATEGORIES = [
  'Music',
  'Food & Drink',
  'Business',
  'Sports & Fitness',
  'Arts',
  'Technology',
  'Education',
  'Lifestyle',
  'Health',
  'Other'
] as const;

export type EventCategory = typeof EVENT_CATEGORIES[number];