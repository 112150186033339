import React, { useCallback, useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import { Event } from '../types/event';
import { GOOGLE_MAPS_CONFIG } from '../config/mapsConfig';

interface EventMapProps {
  events: Event[];
  center: { lat: number; lng: number };
  onEventClick: (event: Event) => void;
  searchRadius?: number;
  searchCenter?: { lat: number; lng: number };
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

const defaultMapOptions = {
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: true,
};

export default function EventMap({ 
  events, 
  center, 
  onEventClick,
  searchRadius,
  searchCenter 
}: EventMapProps) {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Adjust map bounds when search radius or center changes
  useEffect(() => {
    if (map && searchCenter && searchRadius) {
      // Create a circle bounds
      const radiusInMeters = searchRadius * 1000;
      const centerPoint = new google.maps.LatLng(searchCenter.lat, searchCenter.lng);
      const circle = new google.maps.Circle({
        center: centerPoint,
        radius: radiusInMeters
      });
      
      // Fit map to circle bounds
      map.fitBounds(circle.getBounds()!);

      // Add a small padding
      const bounds = map.getBounds();
      if (bounds) {
        map.fitBounds(bounds, 20); // 20px padding
      }
    }
  }, [map, searchCenter, searchRadius]);

  // Handle marker click
  const handleMarkerClick = useCallback((event: Event) => {
    setSelectedEvent(event);
  }, []);

  // Handle info window close
  const handleInfoWindowClose = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  // Handle event details click
  const handleEventClick = useCallback((event: Event) => {
    onEventClick(event);
    setSelectedEvent(null);
  }, [onEventClick]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={searchCenter || center}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={defaultMapOptions}
    >
      {/* Event markers */}
      {events.map((event) => {
        const position = {
          lat: Number(event.location.latitude),
          lng: Number(event.location.longitude),
        };

        return (
          <Marker
            key={event.id}
            position={position}
            onClick={() => handleMarkerClick(event)}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 10,
              fillColor: event.source === 'eventbrite' ? '#FF4081' : '#FF6F61',
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: '#FFFFFF',
            }}
          />
        );
      })}

      {/* Info Window for selected event */}
      {selectedEvent && (
        <InfoWindow
          position={{
            lat: Number(selectedEvent.location.latitude),
            lng: Number(selectedEvent.location.longitude),
          }}
          onCloseClick={handleInfoWindowClose}
        >
          <div className="p-2 max-w-xs">
            <h3 className="font-medium text-gray-900 mb-1">{selectedEvent.title}</h3>
            <p className="text-sm text-gray-600 mb-2">{selectedEvent.location.name}</p>
            <button
              onClick={() => handleEventClick(selectedEvent)}
              className="text-sm text-brand-600 hover:text-brand-700"
            >
              View Details
            </button>
          </div>
        </InfoWindow>
      )}

      {/* Search radius circle */}
      {searchCenter && searchRadius && (
        <Circle
          center={searchCenter}
          radius={searchRadius * 1000}
          options={{
            fillColor: '#FF6F61',
            fillOpacity: 0.1,
            strokeColor: '#FF6F61',
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
      )}
    </GoogleMap>
  );
}