import React from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { Calendar, Clock } from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  error?: string;
  className?: string;
}

const QuickDateButton = ({ label, onClick }: { label: string; onClick: () => void }) => (
  <button
    type="button"
    onClick={onClick}
    className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700"
  >
    {label}
  </button>
);

export default function DateRangePicker({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  error,
  className = ''
}: DateRangePickerProps) {
  const handleQuickSelect = (type: 'today' | 'weekend' | 'week' | 'month') => {
    const today = new Date();
    let start = new Date();
    let end = new Date();

    switch (type) {
      case 'today':
        start.setHours(today.getHours(), today.getMinutes(), 0);
        end.setHours(23, 59, 59);
        break;
      case 'weekend':
        // Find next Saturday
        const daysUntilWeekend = 6 - today.getDay();
        start.setDate(today.getDate() + daysUntilWeekend);
        start.setHours(10, 0, 0);
        end.setDate(start.getDate() + 1);
        end.setHours(18, 0, 0);
        break;
      case 'week':
        start.setHours(today.getHours(), today.getMinutes(), 0);
        end.setDate(today.getDate() + 7);
        end.setHours(23, 59, 59);
        break;
      case 'month':
        start.setHours(today.getHours(), today.getMinutes(), 0);
        end.setMonth(today.getMonth() + 1);
        end.setHours(23, 59, 59);
        break;
    }

    onStartDateChange(start);
    onEndDateChange(end);
  };

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="flex flex-wrap gap-2 mb-4">
        <QuickDateButton label="Today" onClick={() => handleQuickSelect('today')} />
        <QuickDateButton label="This Weekend" onClick={() => handleQuickSelect('weekend')} />
        <QuickDateButton label="Next 7 Days" onClick={() => handleQuickSelect('week')} />
        <QuickDateButton label="This Month" onClick={() => handleQuickSelect('month')} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Start Date and Time */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Date & Time
          </label>
          <div className="space-y-2">
            <div className="relative">
              <DatePicker
                selected={startDate}
                onChange={onStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                placeholderText="Select start date and time"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500"
              />
              <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>

        {/* End Date and Time */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Date & Time
          </label>
          <div className="space-y-2">
            <div className="relative">
              <DatePicker
                selected={endDate}
                onChange={onEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                placeholderText="Select end date and time"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500"
              />
              <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      {error && (
        <p className="text-sm text-red-600">{error}</p>
      )}

      {startDate && endDate && (
        <p className="text-sm text-gray-500">
          From {format(startDate, "MMM d, yyyy h:mm aa")} to {format(endDate, "MMM d, yyyy h:mm aa")}
        </p>
      )}
    </div>
  );
}