import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';

// Convert string to URL-friendly slug
export function slugify(text: string): string {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-')     // Replace spaces with dashes
    .replace(/-+/g, '-');     // Remove consecutive dashes
}

// Generate unique event ID based on title
export async function generateEventId(title: string): Promise<string> {
  const baseSlug = slugify(title);
  const eventsRef = collection(db, 'events');
  
  // Check if base slug exists
  const baseQuery = query(
    eventsRef,
    where('id', '>=', baseSlug),
    where('id', '<=', baseSlug + '\uf8ff')
  );
  
  const snapshot = await getDocs(baseQuery);
  
  if (snapshot.empty) {
    return baseSlug;
  }

  // Get existing slugs that match the pattern baseSlug-N
  const existingSlugs = snapshot.docs.map(doc => doc.id)
    .filter(id => id === baseSlug || id.startsWith(`${baseSlug}-`));

  if (!existingSlugs.includes(baseSlug)) {
    return baseSlug;
  }

  // Find the highest number suffix
  let maxNumber = 0;
  existingSlugs.forEach(slug => {
    const match = slug.match(new RegExp(`^${baseSlug}-(\\d+)$`));
    if (match) {
      const num = parseInt(match[1], 10);
      maxNumber = Math.max(maxNumber, num);
    }
  });

  return `${baseSlug}-${maxNumber + 1}`;
}