import { Analytics, logEvent } from 'firebase/analytics';
import { collection, query, where, getDocs, doc, getDoc, increment, updateDoc, onSnapshot, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { initializeAnalytics } from './firebase';

let analyticsInstance: Analytics | null = null;

const initializeAnalyticsInstance = async () => {
  if (!analyticsInstance) {
    analyticsInstance = await initializeAnalytics();
  }
  return analyticsInstance;
};

const safeLogEvent = async (eventName: string, params?: Record<string, any>) => {
  try {
    const analytics = await initializeAnalyticsInstance();
    if (analytics) {
      logEvent(analytics, eventName, params);
    }
  } catch (error) {
    console.warn(`Failed to log event ${eventName}:`, error);
  }
};

export const trackEventView = async (eventId: string) => {
  try {
    const analyticsRef = doc(db, 'analytics', `event_${eventId}`);
    const analyticsDoc = await getDoc(analyticsRef);

    const updateData = {
      views: increment(1),
      lastViewed: serverTimestamp(),
    };

    if (!analyticsDoc.exists()) {
      // Initialize analytics document if it doesn't exist
      await setDoc(analyticsRef, {
        ...updateData,
        eventId,
        likes: 0,
        saves: 0,
        createdAt: serverTimestamp(),
      });
    } else {
      // Update existing analytics document
      await updateDoc(analyticsRef, updateData);
    }

    await safeLogEvent('event_view', {
      event_id: eventId,
      timestamp: Date.now(),
    });
  } catch (error) {
    console.error('Error tracking event view:', error);
  }
};

export const subscribeToEventAnalytics = (eventId: string, callback: (data: any) => void) => {
  const analyticsRef = doc(db, 'analytics', `event_${eventId}`);
  
  return onSnapshot(analyticsRef, (doc) => {
    const data = doc.data() || { views: 0, likes: 0, saves: 0 };
    callback(data);
  }, (error) => {
    console.error('Error subscribing to analytics:', error);
    callback({ views: 0, likes: 0, saves: 0 });
  });
};