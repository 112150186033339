import React from 'react';
import { Heart, Bookmark } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../store/authStore';
import { isEventInCollection, toggleEventInCollection } from '../services/collections';

interface EventActionsProps {
  eventId: string;
  className?: string;
}

export default function EventActions({ eventId, className = '' }: EventActionsProps) {
  const { user } = useAuthStore();
  const queryClient = useQueryClient();

  const { data: isLiked = false } = useQuery({
    queryKey: ['event-liked', eventId],
    queryFn: () => isEventInCollection(user!.id, eventId, 'liked'),
    enabled: !!user
  });

  const { data: isSaved = false } = useQuery({
    queryKey: ['event-saved', eventId],
    queryFn: () => isEventInCollection(user!.id, eventId, 'saved'),
    enabled: !!user
  });

  const toggleLike = useMutation({
    mutationFn: () => toggleEventInCollection(user!.id, eventId, 'liked'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event-liked', eventId] });
      queryClient.invalidateQueries({ queryKey: ['user-collections'] });
    }
  });

  const toggleSave = useMutation({
    mutationFn: () => toggleEventInCollection(user!.id, eventId, 'saved'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event-saved', eventId] });
      queryClient.invalidateQueries({ queryKey: ['user-collections'] });
    }
  });

  if (!user) return null;

  return (
    <div className={`flex items-center space-x-4 ${className}`}>
      <button
        onClick={() => toggleLike.mutate()}
        className={`flex items-center space-x-1 ${
          isLiked ? 'text-brand-500' : 'text-gray-500 hover:text-brand-500'
        }`}
        disabled={toggleLike.isPending}
      >
        <Heart className={`h-5 w-5 ${isLiked ? 'fill-current' : ''}`} />
      </button>

      <button
        onClick={() => toggleSave.mutate()}
        className={`flex items-center space-x-1 ${
          isSaved ? 'text-brand-500' : 'text-gray-500 hover:text-brand-500'
        }`}
        disabled={toggleSave.isPending}
      >
        <Bookmark className={`h-5 w-5 ${isSaved ? 'fill-current' : ''}`} />
      </button>
    </div>
  );
}