import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, Shield, Users } from 'lucide-react';
import AuthButton from './AuthButton';
import NotificationCenter from './NotificationCenter';
import LanguageSwitcher from './LanguageSwitcher';
import { useAuthStore } from '../store/authStore';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const isHomePage = location.pathname === '/';
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const isAdmin = user?.role === 'admin';
  const isApprovedModerator = user?.role === 'moderator' && user?.approved;

  return (
    <nav className={`${isHomePage ? 'absolute top-0 left-0 right-0 z-10' : 'bg-white shadow-lg'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <Logo isWhite={isHomePage} />
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`p-2 rounded-md ${
                isHomePage ? 'text-white hover:bg-white/10' : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <LanguageSwitcher />
            {isAdmin && (
              <>
                <button
                  onClick={() => navigate('/admin/moderation')}
                  className={`flex items-center space-x-2 ${
                    isHomePage ? 'text-white hover:text-white/80' : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  <Shield className="h-5 w-5" />
                  <span>Admin Moderation</span>
                </button>
                <button
                  onClick={() => navigate('/admin/moderator-approvals')}
                  className={`flex items-center space-x-2 ${
                    isHomePage ? 'text-white hover:text-white/80' : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  <Users className="h-5 w-5" />
                  <span>{t('navigation.moderator_approvals')}</span>
                </button>
              </>
            )}
            {isApprovedModerator && (
              <button
                onClick={() => navigate('/moderation')}
                className={`flex items-center space-x-2 ${
                  isHomePage ? 'text-white hover:text-white/80' : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <Shield className="h-5 w-5" />
                <span>{t('navigation.moderation')}</span>
              </button>
            )}
            <div className={isHomePage ? 'text-white' : 'text-gray-600'}>
              <NotificationCenter isHomePage={isHomePage} />
            </div>
            <AuthButton isHomePage={isHomePage} />
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-4 pt-2 pb-3 space-y-2 shadow-lg bg-white">
            <LanguageSwitcher />
            {isAdmin && (
              <>
                <button
                  onClick={() => {
                    navigate('/admin/moderation');
                    setIsMenuOpen(false);
                  }}
                  className="flex items-center space-x-2 px-3 py-2 rounded-md w-full text-gray-600 hover:bg-gray-100"
                >
                  <Shield className="h-5 w-5" />
                  <span>Admin Moderation</span>
                </button>
                <button
                  onClick={() => {
                    navigate('/admin/moderator-approvals');
                    setIsMenuOpen(false);
                  }}
                  className="flex items-center space-x-2 px-3 py-2 rounded-md w-full text-gray-600 hover:bg-gray-100"
                >
                  <Users className="h-5 w-5" />
                  <span>{t('navigation.moderator_approvals')}</span>
                </button>
              </>
            )}
            {isApprovedModerator && (
              <button
                onClick={() => {
                  navigate('/moderation');
                  setIsMenuOpen(false);
                }}
                className="flex items-center space-x-2 px-3 py-2 rounded-md w-full text-gray-600 hover:bg-gray-100"
              >
                <Shield className="h-5 w-5" />
                <span>{t('navigation.moderation')}</span>
              </button>
            )}
            <div className="px-3 py-2 text-gray-600">
              <NotificationCenter />
            </div>
            <div className="px-3 py-2">
              <AuthButton />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}