import React, { useState, useCallback, useEffect } from 'react';
import { Search } from 'lucide-react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';

interface Location {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
}

interface LocationPickerProps {
  defaultValue?: Location;
  onChange: (location: Location) => void;
  error?: string;
}

const defaultCenter = {
  lat: 52.3676,
  lng: 4.9041
};

export default function LocationPicker({ defaultValue, onChange, error }: LocationPickerProps) {
  const { isLoaded } = useGoogleMaps();
  const [selectedLocation, setSelectedLocation] = useState({
    lat: defaultValue?.latitude ? parseFloat(defaultValue.latitude) : defaultCenter.lat,
    lng: defaultValue?.longitude ? parseFloat(defaultValue.longitude) : defaultCenter.lng
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'nl' },
      types: ['establishment', 'geocode'],
    },
    debounce: 300,
    defaultValue: defaultValue?.address || '',
  });

  useEffect(() => {
    if (defaultValue?.address) {
      setValue(defaultValue.address, false);
      if (defaultValue.latitude && defaultValue.longitude) {
        setSelectedLocation({
          lat: parseFloat(defaultValue.latitude),
          lng: parseFloat(defaultValue.longitude)
        });
      }
    }
  }, [defaultValue, setValue]);

  const handleSelect = async (suggestion: google.maps.places.AutocompletePrediction) => {
    try {
      setValue(suggestion.description, false);
      clearSuggestions();

      const results = await getGeocode({ placeId: suggestion.place_id });
      const { lat, lng } = await getLatLng(results[0]);
      
      setSelectedLocation({ lat, lng });

      const locationData: Location = {
        name: suggestion.structured_formatting.main_text,
        address: suggestion.description,
        latitude: lat.toString(),
        longitude: lng.toString()
      };

      onChange(locationData);
    } catch (error) {
      console.error('Error selecting location:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleMapClick = useCallback(async (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setSelectedLocation({ lat, lng });

    try {
      const results = await getGeocode({
        location: { lat, lng }
      });

      if (results[0]) {
        const address = results[0].formatted_address;
        setValue(address, false);
        
        const locationData: Location = {
          name: results[0].address_components[0].long_name,
          address: address,
          latitude: lat.toString(),
          longitude: lng.toString()
        };

        onChange(locationData);
      }
    } catch (error) {
      console.error('Error reverse geocoding:', error);
    }
  }, [onChange, setValue]);

  if (!isLoaded) {
    return (
      <div className="animate-pulse">
        <div className="h-12 bg-gray-200 rounded-md mb-4"></div>
        <div className="h-[400px] bg-gray-200 rounded-lg"></div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          value={value}
          onChange={handleInputChange}
          disabled={!ready}
          placeholder="Search for a location..."
          className={`block w-full pl-10 pr-3 py-2 border rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-brand-500 ${
            error 
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500'
              : 'border-gray-300 focus:border-brand-500'
          }`}
        />
      </div>

      {error && (
        <p className="text-sm text-red-600" role="alert">{error}</p>
      )}
      
      {status === "OK" && (
        <ul className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {data.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSelect(suggestion)}
              className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
            >
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-900">
                  {suggestion.structured_formatting.main_text}
                </span>
                <span className="ml-2 text-sm text-gray-500">
                  {suggestion.structured_formatting.secondary_text}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}

      <div className="h-[400px] rounded-lg overflow-hidden border border-gray-300">
        <GoogleMap
          zoom={13}
          center={selectedLocation}
          mapContainerClassName="w-full h-full"
          onClick={handleMapClick}
          options={{
            disableDefaultUI: false,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          <Marker position={selectedLocation} />
        </GoogleMap>
      </div>

      <div className="text-sm text-gray-500">
        Click on the map or search for a location to set the event venue
      </div>
    </div>
  );
}