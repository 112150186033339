import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MapPin, Euro, Tag, Upload, Info, AlertCircle, Link } from 'lucide-react';
import { getEventById, updateEvent } from '../services/events';
import { uploadImage } from '../services/storage';
import { useAuthStore } from '../store/authStore';
import LocationPicker from '../components/LocationPicker';
import DateRangePicker from '../components/DateRangePicker';
import { useQuery } from '@tanstack/react-query';
import RichTextEditor from '../components/RichTextEditor';
import { EVENT_CATEGORIES } from '../constants/categories';

export default function EditEventPage() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPaid, setIsPaid] = useState(false);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [description, setDescription] = useState('');

  const { register, handleSubmit, setValue, watch, formState: { errors }, reset } = useForm();

  // Fetch event data
  const { data: event, isLoading: isLoadingEvent } = useQuery({
    queryKey: ['event', eventId],
    queryFn: () => getEventById(eventId!),
    enabled: !!eventId,
    onSuccess: (data) => {
      if (data) {
        // Initialize form with all event data
        reset({
          title: data.title,
          categories: data.categories || [data.category],
          maxAttendees: data.maxAttendees,
          location: data.location,
          ticketUrl: data.ticketUrl,
          price: data.price ? {
            min: data.price.min,
            max: data.price.max
          } : undefined
        });

        // Set description for rich text editor
        setDescription(data.description || '');
        
        // Set dates
        if (data.startDate) setStartDate(new Date(data.startDate));
        if (data.endDate) setEndDate(new Date(data.endDate));
        
        // Set price state and values
        setIsPaid(!!data.price);
        if (data.price) {
          setValue('price.min', data.price.min);
          setValue('price.max', data.price.max);
        }

        // Set location
        setValue('location', data.location);

        // Set categories
        setValue('categories', data.categories || [data.category]);
      }
    }
  });

  // Effect to initialize form values when event data changes
  useEffect(() => {
    if (event) {
      reset({
        title: event.title,
        categories: event.categories || [event.category],
        maxAttendees: event.maxAttendees,
        location: event.location,
        ticketUrl: event.ticketUrl,
        price: event.price ? {
          min: event.price.min,
          max: event.price.max
        } : undefined
      });

      setDescription(event.description || '');
      setStartDate(event.startDate ? new Date(event.startDate) : null);
      setEndDate(event.endDate ? new Date(event.endDate) : null);
      setIsPaid(!!event.price);
    }
  }, [event, reset]);

  const handleLocationChange = (location: any) => {
    setValue('location', location, { shouldValidate: true });
    setLocationError(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const validateLocation = () => {
    const location = watch('location');
    if (!location?.name || !location?.address || !location?.latitude || !location?.longitude) {
      setLocationError('Please select a valid location');
      return false;
    }
    return true;
  };

  const onSubmit = async (data: any) => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates');
      return;
    }

    if (!validateLocation()) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      let imageUrl = event?.imageUrl;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      }

      const eventData = {
        ...data,
        description,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        price: isPaid ? {
          min: parseFloat(data.price.min),
          max: parseFloat(data.price.max)
        } : null,
        imageUrl,
        status: event?.status || 'pending',
        organizer: event?.organizer || {
          id: user!.id,
          name: user!.displayName,
          imageUrl: user!.photoURL,
        },
      };

      await updateEvent(eventId!, eventData);
      navigate('/host-dashboard');
    } catch (error: any) {
      setError(error.message || 'Failed to update event');
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    navigate('/');
    return null;
  }

  if (isLoadingEvent) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Edit Event</h1>
        <p className="mt-2 text-gray-600">Update your event details</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Event Image
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
            {(imagePreview || event?.imageUrl) ? (
              <div className="relative">
                <img
                  src={imagePreview || event?.imageUrl}
                  alt="Preview"
                  className="max-h-64 rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => {
                    setImageFile(null);
                    setImagePreview(null);
                  }}
                  className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
                >
                  <Upload className="h-5 w-5 text-gray-600" />
                </button>
              </div>
            ) : (
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-brand-600 hover:text-brand-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
              </div>
            )}
          </div>
        </div>

        {/* Basic Info */}
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Event Title
            </label>
            <input
              type="text"
              {...register('title', { required: 'Title is required' })}
              defaultValue={event?.title}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-600">{errors.title.message as string}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <RichTextEditor
              value={description}
              onChange={setDescription}
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description.message as string}</p>
            )}
          </div>
        </div>

        {/* Date and Time */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Event Date and Time
          </label>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            error={errors.dates?.message as string}
          />
        </div>

        {/* Location */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Location
          </label>
          <LocationPicker
            defaultValue={event?.location}
            onChange={handleLocationChange}
            error={locationError}
          />
        </div>

        {/* Categories */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Categories
          </label>
          <div className="mt-2 flex flex-wrap gap-2">
            {EVENT_CATEGORIES.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => {
                  const currentCategories = watch('categories') || [];
                  const newCategories = currentCategories.includes(category)
                    ? currentCategories.filter(c => c !== category)
                    : [...currentCategories, category];
                  setValue('categories', newCategories);
                }}
                className={`px-3 py-1 rounded-full text-sm ${
                  (watch('categories') || []).includes(category)
                    ? 'bg-brand-100 text-brand-600'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
          {errors.categories && (
            <p className="mt-1 text-sm text-red-600">{errors.categories.message as string}</p>
          )}
        </div>

        {/* Price */}
        <div>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="isPaid"
              checked={isPaid}
              onChange={(e) => setIsPaid(e.target.checked)}
              className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300 rounded"
            />
            <label htmlFor="isPaid" className="ml-2 block text-sm text-gray-900">
              This is a paid event
            </label>
          </div>

          {isPaid && (
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimum Price
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">€</span>
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register('price.min')}
                    defaultValue={event?.price?.min}
                    className="mt-1 block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Maximum Price
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">€</span>
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    {...register('price.max')}
                    defaultValue={event?.price?.max}
                    className="mt-1 block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Max Attendees */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Maximum Attendees (Optional)
          </label>
          <input
            type="text"
            {...register('maxAttendees')}
            defaultValue={event?.maxAttendees}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
          />
        </div>

        {/* Ticket URL */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ticket URL
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Link className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="url"
              {...register('ticketUrl')}
              defaultValue={event?.ticketUrl}
              placeholder="https://..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500"
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Leave empty to use default registration
          </p>
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-red-400" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Error updating event
                </h3>
                <p className="text-sm text-red-700 mt-2">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/host-dashboard')}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
          >
            {isLoading ? 'Updating...' : 'Update Event'}
          </button>
        </div>
      </form>
    </div>
  );
}