import { doc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { auth, db } from './firebase';
import { uploadImage } from './storage';
import { User } from '../types/user';
import { userSchema } from '../utils/validation';
import { sanitizeObject } from '../utils/sanitize';

export const updateUserProfile = async (
  userId: string,
  data: Partial<User>,
  photoFile?: File | null
): Promise<void> => {
  try {
    // Validate input
    const validatedData = userSchema.partial().parse(data);
    
    // Sanitize input
    const sanitizedData = sanitizeObject(validatedData);
    
    let photoURL = data.photoURL;

    if (photoFile) {
      // Validate file type and size
      if (!photoFile.type.startsWith('image/')) {
        throw new Error('Invalid file type. Only images are allowed.');
      }
      
      if (photoFile.size > 5 * 1024 * 1024) { // 5MB limit
        throw new Error('File size too large. Maximum size is 5MB.');
      }
      
      photoURL = await uploadImage(photoFile);
    }

    const updateData = {
      ...sanitizedData,
      ...(photoURL && { photoURL }),
      updatedAt: new Date().toISOString(),
    };

    // Update Firestore
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updateData);

    // Update Firebase Auth profile
    if (auth.currentUser) {
      await updateProfile(auth.currentUser, {
        displayName: data.displayName,
        photoURL,
      });
    }
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};