import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Shield, Check, X, AlertCircle, Mail } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { getPendingModerators, moderateUser } from '../services/moderation';

export default function ModeratorApprovalPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const queryClient = useQueryClient();

  // Redirect if not admin
  if (!user || user.role !== 'admin') {
    navigate('/');
    return null;
  }

  // Fetch pending moderator requests
  const { data: pendingModerators = [], isLoading } = useQuery({
    queryKey: ['pending-moderators'],
    queryFn: getPendingModerators,
    refetchInterval: 30000, // Refetch every 30 seconds
  });

  // Mutation for approving/rejecting moderators
  const moderatorMutation = useMutation({
    mutationFn: ({ userId, approved }: { userId: string; approved: boolean }) =>
      moderateUser(
        userId,
        approved,
        {
          id: user.id,
          name: user.displayName,
          photoURL: user.photoURL
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pending-moderators'] });
    },
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="sm:flex sm:items-center sm:justify-between mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Moderator Approvals</h1>
          <p className="mt-2 text-sm text-gray-700">
            Review and approve moderator access requests
          </p>
        </div>
      </div>

      {pendingModerators.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <Shield className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No pending requests</h3>
          <p className="mt-1 text-sm text-gray-500">
            There are no moderator requests waiting for approval
          </p>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {pendingModerators.map((moderator) => (
              <li key={moderator.id} className="px-6 py-6">
                <div className="flex flex-col space-y-4">
                  {/* User Info */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center min-w-0">
                      {moderator.photoURL ? (
                        <img
                          src={moderator.photoURL}
                          alt={moderator.displayName}
                          className="h-12 w-12 rounded-full"
                        />
                      ) : (
                        <div className="h-12 w-12 rounded-full bg-gray-200 flex items-center justify-center">
                          <Shield className="h-6 w-6 text-gray-400" />
                        </div>
                      )}
                      <div className="ml-4">
                        <h2 className="text-lg font-medium text-gray-900">{moderator.displayName}</h2>
                        <div className="mt-1 flex items-center text-sm text-gray-500">
                          <Mail className="flex-shrink-0 mr-1.5 h-4 w-4" />
                          {moderator.email}
                        </div>
                        <div className="mt-1 flex items-center text-sm text-gray-500">
                          <AlertCircle className="flex-shrink-0 mr-1.5 h-4 w-4" />
                          Requested {format(new Date(moderator.createdAt), 'PPP')}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Motivation */}
                  {moderator.moderatorMotivation && (
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h3 className="text-sm font-medium text-gray-900 mb-2">Motivation</h3>
                      <p className="text-sm text-gray-700 whitespace-pre-line">
                        {moderator.moderatorMotivation}
                      </p>
                    </div>
                  )}

                  {/* Action Buttons */}
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={() => moderatorMutation.mutate({ userId: moderator.id, approved: true })}
                      disabled={moderatorMutation.isPending}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      <Check className="h-4 w-4 mr-2" />
                      Approve
                    </button>
                    <button
                      onClick={() => moderatorMutation.mutate({ userId: moderator.id, approved: false })}
                      disabled={moderatorMutation.isPending}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <X className="h-4 w-4 mr-2" />
                      Reject
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}