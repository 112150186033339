import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleMapsProvider } from './contexts/GoogleMapsContext';
import RootLayout from './layouts/RootLayout';
import HomePage from './pages/HomePage';
import EventsPage from './pages/EventsPage';
import EventDetailPage from './pages/EventDetailPage';
import CreateEventPage from './pages/CreateEventPage';
import EditEventPage from './pages/EditEventPage';
import ModerationPage from './pages/ModerationPage';
import AdminModerationPage from './pages/AdminModerationPage';
import ModeratorApprovalPage from './pages/ModeratorApprovalPage';
import AdminBulkUploadPage from './pages/AdminBulkUploadPage';
import ProfilePage from './pages/ProfilePage';
import HostDashboardPage from './pages/HostDashboardPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import ConnectionStatus from './components/ConnectionStatus';
import { initializeAnalytics } from './services/firebase';
import { useTranslation } from 'react-i18next';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'events',
        element: <EventsPage />,
      },
      {
        path: 'event/:eventId',
        element: <EventDetailPage />,
      },
      {
        path: 'event/:eventId/edit',
        element: <EditEventPage />,
      },
      {
        path: 'create-event',
        element: <CreateEventPage />,
      },
      {
        path: 'moderation',
        element: <ModerationPage />,
      },
      {
        path: 'admin/moderation',
        element: <AdminModerationPage />,
      },
      {
        path: 'admin/moderator-approvals',
        element: <ModeratorApprovalPage />,
      },
      {
        path: 'admin/bulk-upload',
        element: <AdminBulkUploadPage />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'host-dashboard',
        element: <HostDashboardPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'terms-of-service',
        element: <TermsOfServicePage />,
      },
    ],
  },
]);

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await initializeAnalytics();
        // Load saved language preference
        const savedLang = localStorage.getItem('i18nextLng');
        if (savedLang) {
          await i18n.changeLanguage(savedLang);
        }
        setIsInitialized(true);
      } catch (error) {
        console.error('Error during app initialization:', error);
        setError('Failed to initialize the application. Please try again later.');
        setIsInitialized(true);
      }
    };

    initializeApp();
  }, [i18n]);

  if (!isInitialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-xl font-semibold text-gray-900 mb-2">Error</h1>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <GoogleMapsProvider>
          <RouterProvider router={router} />
          <ConnectionStatus />
        </GoogleMapsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;