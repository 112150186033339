import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export default function TermsOfServicePage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <Link
        to="/"
        className="inline-flex items-center text-brand-600 hover:text-brand-800 mb-8"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to home
      </Link>

      <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
      
      <div className="prose max-w-none">
        <p className="text-gray-600 mb-8">Last updated: {new Date().toLocaleDateString()}</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Agreement to Terms</h2>
          <p>
            By accessing or using ThrillDash ("the Service"), you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using the Service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Use License</h2>
          <p>
            Permission is granted to temporarily access and use the Service for personal, non-commercial purposes. This license does not include:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Modifying or copying the materials</li>
            <li>Using the materials for commercial purposes</li>
            <li>Attempting to decompile or reverse engineer any software</li>
            <li>Removing any copyright or proprietary notations</li>
            <li>Transferring the materials to another person</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. User Accounts</h2>
          <p>When creating an account, you agree to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide accurate and complete information</li>
            <li>Maintain the security of your account</li>
            <li>Promptly update your account information</li>
            <li>Accept responsibility for all activities under your account</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Event Creation and Participation</h2>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">4.1 Event Organizers</h3>
          <p>As an event organizer, you:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Must provide accurate event information</li>
            <li>Are responsible for event management and safety</li>
            <li>Must comply with applicable laws and regulations</li>
            <li>Accept our payment and refund policies</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-800 mb-2">4.2 Event Participants</h3>
          <p>As an event participant, you:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Accept the event's terms and conditions</li>
            <li>Understand refund and cancellation policies</li>
            <li>Assume responsibility for your participation</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Payments and Refunds</h2>
          <p>
            All payments are processed securely through our payment processors. Refund policies are set by event organizers and must be clearly stated in event descriptions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Prohibited Activities</h2>
          <p>You may not:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Use the Service for illegal purposes</li>
            <li>Post false or misleading information</li>
            <li>Interfere with the Service's operation</li>
            <li>Collect user information without consent</li>
            <li>Create multiple accounts</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Intellectual Property</h2>
          <p>
            The Service and its original content, features, and functionality are owned by ThrillDash and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Limitation of Liability</h2>
          <p>
            ThrillDash shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use the Service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">9. Indemnification</h2>
          <p>
            You agree to indemnify and hold ThrillDash harmless from any claims, losses, liability, damages, and expenses arising from your use of the Service or violation of these terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">10. Termination</h2>
          <p>
            We may terminate or suspend your account and access to the Service immediately, without prior notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties, or for any other reason.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">11. Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">12. Changes to Terms</h2>
          <p>
            We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through the Service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">13. Contact Information</h2>
          <p>
            For questions about these Terms of Service, please contact us at:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Email: legal@thrilldash.com</li>
            <li>Address: [Your Business Address]</li>
          </ul>
        </section>
      </div>
    </div>
  );
}