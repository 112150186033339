import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  updateProfile,
  UserCredential 
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { User } from '../types/user';
import { SignUpData, SignInData } from '../types/auth';
import { 
  auth, 
  db, 
  signInWithGoogle as firebaseSignInGoogle,
  signInWithMicrosoft as firebaseSignInMicrosoft,
  signOutUser 
} from './firebase';

// Function to verify reCAPTCHA token
async function verifyRecaptcha(token: string) {
  try {
    const baseUrl = import.meta.env.DEV 
      ? 'http://localhost:5001/thrilldash/us-central1'
      : 'https://us-central1-thrilldash.cloudfunctions.net';

    const response = await fetch(`${baseUrl}/verifyRecaptcha`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
      mode: 'cors'
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'reCAPTCHA verification failed');
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error('Error verifying reCAPTCHA:', error);
    throw new Error('Failed to verify reCAPTCHA. Please try again.');
  }
}

export const signUp = async (data: SignUpData): Promise<User> => {
  try {
    const { email, password, displayName, role, recaptchaToken } = data;
    
    // Verify reCAPTCHA
    const isVerified = await verifyRecaptcha(recaptchaToken);
    if (!isVerified) {
      throw new Error('reCAPTCHA verification failed');
    }

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;

    await updateProfile(firebaseUser, { displayName });

    const userData: User = {
      id: firebaseUser.uid,
      email: firebaseUser.email || '',
      displayName,
      role,
      createdAt: new Date().toISOString(),
      lastLoginAt: new Date().toISOString()
    };

    if (firebaseUser.photoURL) {
      userData.photoURL = firebaseUser.photoURL;
    }

    await setDoc(doc(db, 'users', firebaseUser.uid), userData);
    return userData;
  } catch (error: any) {
    console.error('Error during sign up:', error);
    throw error;
  }
};

export const signIn = async (data: SignInData): Promise<User> => {
  try {
    const { email, password } = data;
    
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;

    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    
    if (!userDoc.exists()) {
      throw new Error('User data not found');
    }

    const userData = userDoc.data() as User;

    await setDoc(doc(db, 'users', firebaseUser.uid), {
      ...userData,
      lastLoginAt: new Date().toISOString()
    }, { merge: true });

    return userData;
  } catch (error: any) {
    console.error('Error during sign in:', error);
    throw error;
  }
};

export const handleOAuthSignIn = async (result: UserCredential): Promise<{ user: User | null, isNewUser: boolean }> => {
  const { user: firebaseUser } = result;

  // Check if user exists in Firestore
  const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
  
  if (userDoc.exists()) {
    // Update existing user's last login
    const userData = userDoc.data() as User;
    await setDoc(doc(db, 'users', firebaseUser.uid), {
      ...userData,
      lastLoginAt: new Date().toISOString()
    }, { merge: true });
    return { user: userData, isNewUser: false };
  } else {
    // Return initial user data for new users
    const initialUserData = {
      id: firebaseUser.uid,
      email: firebaseUser.email || '',
      displayName: firebaseUser.displayName || 'Anonymous',
      createdAt: new Date().toISOString(),
      lastLoginAt: new Date().toISOString(),
      ...(firebaseUser.photoURL && { photoURL: firebaseUser.photoURL })
    };
    return { user: null, isNewUser: true, initialUserData };
  }
};

export const signInWithGoogle = async () => {
  try {
    const result = await firebaseSignInGoogle();
    return handleOAuthSignIn(result);
  } catch (error) {
    console.error('Error during Google sign in:', error);
    throw error;
  }
};

export const signInWithMicrosoft = async () => {
  try {
    const result = await firebaseSignInMicrosoft();
    return handleOAuthSignIn(result);
  } catch (error) {
    console.error('Error during Microsoft sign in:', error);
    throw error;
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await signOutUser();
  } catch (error) {
    console.error('Error during sign out:', error);
    throw error;
  }
};

export const getCurrentUser = async (): Promise<User | null> => {
  const firebaseUser = auth.currentUser;
  if (!firebaseUser) return null;

  try {
    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    if (userDoc.exists()) {
      return userDoc.data() as User;
    }
    return null;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};