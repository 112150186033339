import { useMemo } from 'react';
import { Event } from '../types/event';
import { TimeRangeType, getDateRangeForType, isEventInDateRange } from '../utils/dateRanges';

interface UseEventFiltersProps {
  events: Event[];
  searchQuery: string;
  selectedCategories: string[];
  activeTimeFilter: TimeRangeType;
  sortBy: 'date' | 'popularity';
  searchLocation?: { lat: number; lng: number; radius: number } | null;
  showPastEvents: boolean;
}

export function useEventFilters({
  events,
  searchQuery,
  selectedCategories,
  activeTimeFilter,
  sortBy,
  searchLocation,
  showPastEvents
}: UseEventFiltersProps) {
  return useMemo(() => {
    let filteredEvents = [...events];

    // First apply past/upcoming filter
    const now = new Date();
    filteredEvents = filteredEvents.filter(event => {
      const eventEndDate = new Date(event.endDate);
      if (showPastEvents) {
        return eventEndDate < now;
      } else {
        return eventEndDate >= now;
      }
    });

    // Category filter
    if (selectedCategories.length > 0) {
      filteredEvents = filteredEvents.filter(event => {
        const eventCategories = Array.isArray(event.categories) ? event.categories : [event.category];
        return selectedCategories.some(cat => eventCategories.includes(cat));
      });
    }

    // Search query filter
    if (searchQuery) {
      const searchTerms = searchQuery.toLowerCase().split(' ').filter(Boolean);
      filteredEvents = filteredEvents.filter(event => {
        const searchableText = `
          ${event.title}
          ${event.description}
          ${event.location.name}
          ${event.location.address}
          ${Array.isArray(event.categories) ? event.categories.join(' ') : event.category}
        `.toLowerCase();
        return searchTerms.some(term => searchableText.includes(term));
      });
    }

    // Time range filter (only apply if not showing past events)
    if (!showPastEvents && activeTimeFilter && activeTimeFilter !== 'all') {
      const dateRange = getDateRangeForType(activeTimeFilter);
      if (dateRange) {
        filteredEvents = filteredEvents.filter(event => isEventInDateRange(event, dateRange));
      }
    }

    // Location filter
    if (searchLocation) {
      const { lat, lng, radius } = searchLocation;
      filteredEvents = filteredEvents.filter(event => {
        const distance = calculateDistance(
          lat,
          lng,
          Number(event.location.latitude),
          Number(event.location.longitude)
        );
        return distance <= radius;
      });
    }

    // Sort events
    filteredEvents.sort((a, b) => {
      if (sortBy === 'popularity') {
        return (b.shares || 0) - (a.shares || 0);
      }
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    return filteredEvents;
  }, [events, searchQuery, selectedCategories, activeTimeFilter, sortBy, searchLocation, showPastEvents]);
}

// Helper function to calculate distance between coordinates using Haversine formula
function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}

function toRad(value: number): number {
  return value * Math.PI / 180;
}