import React, { useEffect, useMemo, Suspense } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { MapPin, Calendar, Euro, ArrowLeft, Globe, User, Shield, Eye, Heart, Bookmark, Ticket } from 'lucide-react';
import { getEventById } from '../services/events';
import { useAuthStore } from '../store/authStore';
import { trackEventView } from '../services/analytics';
import EventActions from '../components/EventActions';
import DOMPurify from 'dompurify';

// Lazy load EventAnalytics component
const EventAnalytics = React.lazy(() => import('../components/EventAnalytics'));

// Memoized formatting functions
const formatDate = (dateString: string) => {
  try {
    return format(parseISO(dateString), 'EEEE, MMMM d, yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date not available';
  }
};

const formatTime = (dateString: string) => {
  try {
    return format(parseISO(dateString), 'h:mm a');
  } catch (error) {
    console.error('Error formatting time:', error);
    return 'Time not available';
  }
};

// Function to sanitize and create markup
const createMarkup = (content: string) => {
  return {
    __html: DOMPurify.sanitize(content, {
      ALLOWED_TAGS: [
        'p', 'br', 'b', 'i', 'em', 'strong', 'u', 'ul', 'ol', 'li',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'blockquote'
      ],
      ALLOWED_ATTR: ['href', 'target', 'rel'],
    })
  };
};

// Memoized components
const EventHeader = React.memo(({ event }: { event: any }) => (
  <div className="flex items-center justify-between mb-6">
    <h1 className="text-3xl font-bold text-gray-900">{event.title}</h1>
    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
      {event.category}
    </span>
  </div>
));

EventHeader.displayName = 'EventHeader';

const EventDetails = React.memo(({ event }: { event: any }) => (
  <div className="space-y-4">
    <div className="flex items-center">
      <Calendar className="h-5 w-5 text-gray-500 mr-3" />
      <div>
        <p className="text-sm font-medium text-gray-900">
          {formatDate(event.startDate)}
        </p>
        <p className="text-sm text-gray-500">
          {formatTime(event.startDate)} - {formatTime(event.endDate)}
        </p>
      </div>
    </div>

    <div className="flex items-center">
      <MapPin className="h-5 w-5 text-gray-500 mr-3" />
      <div>
        <p className="text-sm font-medium text-gray-900">
          {event.location.name}
        </p>
        <p className="text-sm text-gray-500">{event.location.address}</p>
      </div>
    </div>

    <div className="flex items-center">
      <User className="h-5 w-5 text-gray-500 mr-3" />
      <div>
        <p className="text-sm font-medium text-gray-900">Organizer</p>
        <p className="text-sm text-gray-500">{event.organizer.name}</p>
      </div>
    </div>

    <a
      href={event.ticketUrl || '#'}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-full"
    >
      <Ticket className="h-4 w-4 mr-2" />
      Get Tickets
    </a>
  </div>
));

EventDetails.displayName = 'EventDetails';

export default function EventDetailPage() {
  const { eventId } = useParams();
  const { user } = useAuthStore();

  const { data: event, isLoading, error } = useQuery({
    queryKey: ['event', eventId],
    queryFn: () => getEventById(eventId!),
    enabled: !!eventId,
    retry: false,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Track view only once when event data is loaded
  useEffect(() => {
    let isSubscribed = true;
    
    if (event?.id && isSubscribed) {
      trackEventView(event.id).catch(console.error);
    }

    return () => {
      isSubscribed = false;
    };
  }, [event?.id]);

  const isHostOrModerator = useMemo(() => 
    user?.role === 'moderator' || event?.organizer.id === user?.id,
    [user?.role, event?.organizer.id, user?.id]
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600" />
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8 text-center">
        <h2 className="text-2xl font-bold text-gray-900">Event not found</h2>
        <p className="mt-2 text-gray-600">
          The event you are looking for does not exist or has been removed.
        </p>
        <Link
          to="/events"
          className="mt-4 inline-flex items-center text-indigo-600 hover:text-indigo-800"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Return to events
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <Link
        to="/events"
        className="inline-flex items-center text-indigo-600 hover:text-indigo-800 mb-6"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to events
      </Link>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="relative h-96">
          <img
            src={event.imageUrl || 'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?auto=format&fit=crop&w=800'}
            alt={event.title}
            className="w-full h-full object-cover"
            loading="lazy"
          />
          {event.price && (
            <div className="absolute top-4 right-4 bg-white px-4 py-2 rounded-full shadow-md">
              <div className="flex items-center space-x-1">
                <Euro className="h-5 w-5 text-green-600" />
                <span className="text-lg font-medium text-gray-900">
                  {event.price.min === event.price.max
                    ? `${event.price.min}`
                    : `${event.price.min} - ${event.price.max}`}
                </span>
              </div>
            </div>
          )}
          <div className="absolute top-4 left-4">
            <EventActions eventId={event.id} />
          </div>
        </div>

        <div className="p-8">
          <EventHeader event={event} />

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="col-span-2">
              <div className="prose max-w-none">
                <h2 className="text-xl font-semibold mb-4">About this event</h2>
                <div 
                  className="text-gray-700 whitespace-pre-line"
                  dangerouslySetInnerHTML={createMarkup(event.description)}
                />
              </div>

              {isHostOrModerator && (
                <div className="mt-8">
                  <Suspense fallback={<div>Loading analytics...</div>}>
                    <EventAnalytics event={event} />
                  </Suspense>
                </div>
              )}
            </div>

            <div className="space-y-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <EventDetails event={event} />

                {event.status === 'approved' && event.moderatedBy && (
                  <div className="flex items-center pt-4 border-t mt-4">
                    <Shield className="h-5 w-5 text-gray-500 mr-3" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">Approved by</p>
                      <div className="flex items-center space-x-2">
                        {event.moderatedBy.photoURL ? (
                          <img
                            src={event.moderatedBy.photoURL}
                            alt={event.moderatedBy.name}
                            className="h-6 w-6 rounded-full"
                            loading="lazy"
                          />
                        ) : (
                          <div className="h-6 w-6 rounded-full bg-gray-200 flex items-center justify-center">
                            <span className="text-xs font-medium text-gray-500">
                              {event.moderatedBy.name?.[0] || 'A'}
                            </span>
                          </div>
                        )}
                        <span className="text-sm text-gray-500">
                          {event.moderatedBy.name || 'Anonymous'}
                        </span>
                      </div>
                      {event.moderatedAt && (
                        <p className="text-xs text-gray-500 mt-1">
                          {formatDate(event.moderatedAt)}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}