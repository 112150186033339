import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Calendar, Search, MapPin, TrendingUp, Star, Users, Euro } from 'lucide-react';
import { searchEvents } from '../services/events';
import { useEventStore } from '../store/eventStore';
import EventList from '../components/EventList';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSearchQuery, setFilters } = useEventStore();
  const [searchInput, setSearchInput] = useState('');

  // Get trending events - top 6 most popular upcoming events
  const { data: events = [], isLoading } = useQuery({
    queryKey: ['trending-events'],
    queryFn: async () => {
      const allEvents = await searchEvents({ 
        filters: { 
          status: 'approved',
          showPastEvents: false
        }
      });
      
      // Sort by shares and get top 6
      return allEvents
        .sort((a, b) => (b.shares || 0) - (a.shares || 0))
        .slice(0, 6);
    },
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchInput.trim()) {
      // If there's a search term, navigate with search state
      setSearchQuery(searchInput);
      setFilters({}); // Reset any existing filters
      navigate('/events', { 
        state: { 
          fromSearch: true,
          searchQuery: searchInput 
        }
      });
    } else {
      // If search is empty, just navigate to events page
      setSearchQuery('');
      setFilters({});
      navigate('/events');
    }
  };

  return (
    <div>
      {/* Hero Section */}
      <div className="bg-black h-[65vh] flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <div className="text-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4 sm:mb-6 hero-title max-w-4xl mx-auto leading-tight px-2">
              {t('home.hero.title')}
            </h1>
            <p className="text-base sm:text-lg md:text-xl text-gray-300 mb-8 sm:mb-12 max-w-2xl mx-auto subtitle leading-relaxed px-4">
              {t('home.hero.subtitle')}
            </p>
            
            <form onSubmit={handleSearch} className="max-w-3xl mx-auto px-4">
              <div className="flex flex-col sm:flex-row gap-3">
                <div className="relative flex-1">
                  <div className="absolute inset-y-0 left-0 pl-3 sm:pl-5 flex items-center pointer-events-none">
                    <Search className="h-5 w-5 sm:h-6 sm:w-6 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder={t('home.search.placeholder')}
                    className="block w-full pl-10 sm:pl-14 pr-4 sm:pr-14 py-3 sm:py-4 text-base sm:text-lg border border-gray-700 rounded-full leading-7 bg-gray-900 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500 shadow-lg"
                  />
                </div>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-medium border border-transparent rounded-full shadow-lg text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 transition-colors duration-200 min-h-[3rem]"
                >
                  {t('home.search.button')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Popular Events Section */}
      <div className="bg-white py-8 sm:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-0 mb-6 sm:mb-8">
            <div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900">{t('home.trending.title')}</h2>
              <p className="mt-1 text-sm sm:text-base text-gray-500">{t('home.trending.subtitle')}</p>
            </div>
            <button
              onClick={() => navigate('/events')}
              className="text-brand-600 hover:text-brand-800 font-medium text-sm sm:text-base"
            >
              {t('events.view_all')}
            </button>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-48 sm:h-64">
              <div className="animate-spin rounded-full h-10 w-10 sm:h-12 sm:w-12 border-b-2 border-brand-600" />
            </div>
          ) : (
            <EventList 
              events={events} 
              onEventClick={(event) => navigate(`/event/${event.id}`)}
            />
          )}
        </div>
      </div>
    </div>
  );
}