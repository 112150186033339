import { useMemo } from 'react';
import { Event } from '../types/event';

interface UseDashboardFiltersProps {
  events: Event[];
  searchQuery: string;
  activeTab: string;
}

export function useDashboardFilters({
  events,
  searchQuery,
  activeTab,
}: UseDashboardFiltersProps) {
  // Calculate stats
  const stats = useMemo(() => {
    return events.reduce((acc, event) => {
      const isPastEvent = new Date(event.endDate) < new Date();
      
      if (isPastEvent) {
        acc.past++;
        return acc;
      }
      
      if (event.status === 'approved') acc.published++;
      else if (event.status === 'pending') acc.pending++;
      else if (event.status === 'rejected') acc.rejected++;
      else if (event.status === 'draft') acc.draft++;
      return acc;
    }, { published: 0, pending: 0, rejected: 0, draft: 0, past: 0 });
  }, [events]);

  // Filter events based on active tab and search query
  const filteredEvents = useMemo(() => {
    return events
      .filter(event => {
        const isPastEvent = new Date(event.endDate) < new Date();
        
        if (activeTab === 'past') return isPastEvent;
        if (isPastEvent) return false;

        switch (activeTab) {
          case 'published':
            return event.status === 'approved';
          case 'pending':
            return event.status === 'pending';
          case 'rejected':
            return event.status === 'rejected';
          case 'draft':
            return event.status === 'draft';
          default:
            return false;
        }
      })
      .filter(event => 
        searchQuery ? (
          event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          event.description.toLowerCase().includes(searchQuery.toLowerCase())
        ) : true
      );
  }, [events, activeTab, searchQuery]);

  return {
    stats,
    filteredEvents
  };
}