import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
  limit,
  Timestamp
} from 'firebase/firestore';
import { db } from './firebase';

export interface NotificationData {
  userId: string;
  type: 'event_moderation' | 'moderator_request' | 'moderator_approval' | 'comment_reply' | 'event_reminder';
  message: string;
  eventId?: string;
  data?: Record<string, any>;
}

export const getUserNotifications = async (userId: string) => {
  try {
    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(50)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt instanceof Timestamp
        ? doc.data().createdAt.toDate().toISOString()
        : doc.data().createdAt
    }));
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
};

export const createNotification = async ({
  userId,
  type,
  message,
  eventId,
  data = {}
}: NotificationData) => {
  try {
    await addDoc(collection(db, 'notifications'), {
      userId,
      type,
      message,
      ...(eventId && { eventId }), // Only include eventId if it exists
      data,
      read: false,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId: string) => {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, {
      read: true
    });
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};