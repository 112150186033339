import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_CONFIG } from '../config/mapsConfig';

interface GoogleMapsLoaderProps {
  children: React.ReactNode;
}

// Create a single loader instance
const loader = new Loader({
  apiKey: GOOGLE_MAPS_CONFIG.apiKey,
  version: "weekly",
  libraries: GOOGLE_MAPS_CONFIG.options.libraries,
  id: 'google-map-script',
  authReferrerPolicy: 'origin',
});

export default function GoogleMapsLoader({ children }: GoogleMapsLoaderProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loader.load()
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error('Error loading Google Maps:', err);
        setError('Failed to load Google Maps');
      });
  }, []);

  if (error) {
    return <div className="text-red-600">Error loading Google Maps: {error}</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600" />
      </div>
    );
  }

  return <>{children}</>;
}