import { 
  collection, 
  query, 
  where, 
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  serverTimestamp,
  getDoc,
  orderBy,
  limit,
  Timestamp,
  or
} from 'firebase/firestore';
import { db } from './firebase';
import { Event } from '../types/event';
import { User } from '../types/user';
import { createNotification } from './notifications';

// Event moderation functions
export const moderateEvent = async (
  eventId: string,
  status: 'approved' | 'rejected' | 'pending' | 'draft',
  moderator: { id: string; name: string; photoURL?: string },
  notes?: string
) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
      throw new Error('Event not found');
    }

    const eventData = eventDoc.data();

    // Update event status
    await updateDoc(eventRef, {
      status,
      moderationNotes: notes || '',
      moderatedBy: {
        id: moderator.id,
        name: moderator.name,
        photoURL: moderator.photoURL
      },
      moderatedAt: serverTimestamp()
    });

    // Create notification for event organizer
    await createNotification({
      userId: eventData.organizer.id,
      type: 'event_moderation',
      eventId,
      message: `Your event "${eventData.title}" has been ${status}${notes ? `: ${notes}` : ''}`,
      data: {
        status,
        moderator: moderator.name
      }
    });

    return true;
  } catch (error) {
    console.error('Error moderating event:', error);
    throw error;
  }
};

export const getPendingEvents = async () => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(
      eventsRef,
      or(
        where('status', '==', 'pending'),
        where('status', '==', null)
      ),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate instanceof Timestamp ? 
        doc.data().startDate.toDate().toISOString() : 
        doc.data().startDate,
      endDate: doc.data().endDate instanceof Timestamp ? 
        doc.data().endDate.toDate().toISOString() : 
        doc.data().endDate,
      createdAt: doc.data().createdAt instanceof Timestamp ? 
        doc.data().createdAt.toDate().toISOString() : 
        doc.data().createdAt,
      updatedAt: doc.data().updatedAt instanceof Timestamp ? 
        doc.data().updatedAt.toDate().toISOString() : 
        doc.data().updatedAt,
      moderatedAt: doc.data().moderatedAt instanceof Timestamp ? 
        doc.data().moderatedAt.toDate().toISOString() : 
        doc.data().moderatedAt,
      location: {
        ...doc.data().location,
        latitude: String(doc.data().location.latitude),
        longitude: String(doc.data().location.longitude)
      },
      categories: Array.isArray(doc.data().categories) ? doc.data().categories : [doc.data().category],
      maxAttendees: String(doc.data().maxAttendees || ''),
      shares: Number(doc.data().shares || 0)
    } as Event));
  } catch (error) {
    console.error('Error fetching pending events:', error);
    return [];
  }
};

export const getApprovedEvents = async (maxEvents = 50) => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(
      eventsRef,
      where('status', '==', 'approved'),
      orderBy('moderatedAt', 'desc'),
      limit(maxEvents)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate instanceof Timestamp ? 
        doc.data().startDate.toDate().toISOString() : 
        doc.data().startDate,
      endDate: doc.data().endDate instanceof Timestamp ? 
        doc.data().endDate.toDate().toISOString() : 
        doc.data().endDate,
      createdAt: doc.data().createdAt instanceof Timestamp ? 
        doc.data().createdAt.toDate().toISOString() : 
        doc.data().createdAt,
      updatedAt: doc.data().updatedAt instanceof Timestamp ? 
        doc.data().updatedAt.toDate().toISOString() : 
        doc.data().updatedAt,
      moderatedAt: doc.data().moderatedAt instanceof Timestamp ? 
        doc.data().moderatedAt.toDate().toISOString() : 
        doc.data().moderatedAt,
      location: {
        ...doc.data().location,
        latitude: String(doc.data().location.latitude),
        longitude: String(doc.data().location.longitude)
      },
      categories: Array.isArray(doc.data().categories) ? doc.data().categories : [doc.data().category],
      maxAttendees: String(doc.data().maxAttendees || ''),
      shares: Number(doc.data().shares || 0)
    } as Event));
  } catch (error) {
    console.error('Error fetching approved events:', error);
    return [];
  }
};

export const getRejectedEvents = async (maxEvents = 50) => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(
      eventsRef,
      where('status', '==', 'rejected'),
      orderBy('moderatedAt', 'desc'),
      limit(maxEvents)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate instanceof Timestamp ? 
        doc.data().startDate.toDate().toISOString() : 
        doc.data().startDate,
      endDate: doc.data().endDate instanceof Timestamp ? 
        doc.data().endDate.toDate().toISOString() : 
        doc.data().endDate,
      createdAt: doc.data().createdAt instanceof Timestamp ? 
        doc.data().createdAt.toDate().toISOString() : 
        doc.data().createdAt,
      updatedAt: doc.data().updatedAt instanceof Timestamp ? 
        doc.data().updatedAt.toDate().toISOString() : 
        doc.data().updatedAt,
      moderatedAt: doc.data().moderatedAt instanceof Timestamp ? 
        doc.data().moderatedAt.toDate().toISOString() : 
        doc.data().moderatedAt,
      location: {
        ...doc.data().location,
        latitude: String(doc.data().location.latitude),
        longitude: String(doc.data().location.longitude)
      },
      categories: Array.isArray(doc.data().categories) ? doc.data().categories : [doc.data().category],
      maxAttendees: String(doc.data().maxAttendees || ''),
      shares: Number(doc.data().shares || 0)
    } as Event));
  } catch (error) {
    console.error('Error fetching rejected events:', error);
    return [];
  }
};

export const getDraftEvents = async (maxEvents = 50) => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(
      eventsRef,
      where('status', '==', 'draft'),
      orderBy('createdAt', 'desc'),
      limit(maxEvents)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate instanceof Timestamp ? 
        doc.data().startDate.toDate().toISOString() : 
        doc.data().startDate,
      endDate: doc.data().endDate instanceof Timestamp ? 
        doc.data().endDate.toDate().toISOString() : 
        doc.data().endDate,
      createdAt: doc.data().createdAt instanceof Timestamp ? 
        doc.data().createdAt.toDate().toISOString() : 
        doc.data().createdAt,
      updatedAt: doc.data().updatedAt instanceof Timestamp ? 
        doc.data().updatedAt.toDate().toISOString() : 
        doc.data().updatedAt,
      moderatedAt: doc.data().moderatedAt instanceof Timestamp ? 
        doc.data().moderatedAt.toDate().toISOString() : 
        doc.data().moderatedAt,
      location: {
        ...doc.data().location,
        latitude: String(doc.data().location.latitude),
        longitude: String(doc.data().location.longitude)
      },
      categories: Array.isArray(doc.data().categories) ? doc.data().categories : [doc.data().category],
      maxAttendees: String(doc.data().maxAttendees || ''),
      shares: Number(doc.data().shares || 0)
    } as Event));
  } catch (error) {
    console.error('Error fetching draft events:', error);
    return [];
  }
};

// User moderation functions
export const getPendingModerators = async (): Promise<User[]> => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(
      usersRef,
      where('role', '==', 'moderator'),
      where('approved', '==', false)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as User));
  } catch (error) {
    console.error('Error fetching pending moderators:', error);
    return [];
  }
};

export const moderateUser = async (
  userId: string,
  approved: boolean,
  admin: { id: string; name: string; photoURL?: string }
) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();

    // Update user data based on approval status
    if (approved) {
      await updateDoc(userRef, {
        approved: true,
        approvedAt: serverTimestamp(),
        approvedBy: {
          id: admin.id,
          name: admin.name,
          photoURL: admin.photoURL
        },
        pendingApproval: false
      });

      await createNotification({
        userId,
        type: 'moderator_approval',
        message: 'Your moderator access request has been approved! You now have access to moderation features.',
        data: {
          approved: true,
          approvedBy: admin.name
        }
      });
    } else {
      // If rejected, change role to attendee and send rejection notification
      await updateDoc(userRef, {
        role: 'attendee',
        approved: false,
        approvedAt: null,
        approvedBy: null,
        pendingApproval: false
      });

      await createNotification({
        userId,
        type: 'moderator_approval',
        message: `Your moderator access request has been rejected. If you believe this was a mistake, please contact moderation@thrilldash.com.`,
        data: {
          approved: false,
          approvedBy: admin.name
        }
      });
    }

    return true;
  } catch (error) {
    console.error('Error moderating user:', error);
    throw error;
  }
};