import React from 'react';
import { Wifi, WifiOff } from 'lucide-react';
import { useFirebaseConnection } from '../hooks/useFirebaseConnection';

export default function ConnectionStatus() {
  const { isOnline, isConnected, error } = useFirebaseConnection();

  if (isOnline && isConnected && !error) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4 z-50">
      <div className="bg-white rounded-lg shadow-lg p-4 flex items-center space-x-2">
        {isOnline ? (
          <>
            <Wifi className="h-5 w-5 text-yellow-500" />
            <span className="text-sm text-gray-600">
              Connecting to server...
            </span>
          </>
        ) : (
          <>
            <WifiOff className="h-5 w-5 text-red-500" />
            <span className="text-sm text-gray-600">
              You're offline. Some features may be limited.
            </span>
          </>
        )}
      </div>
    </div>
  );
}