import { z } from 'zod';
import DOMPurify from 'dompurify';

// Enhanced date validation
const validateDateTime = (value: string) => {
  try {
    const date = new Date(value);
    if (isNaN(date.getTime())) return false;
    return true;
  } catch {
    return false;
  }
};

// Enhanced description validation for rich text
const validateDescription = (value: string) => {
  // Sanitize HTML content
  const clean = DOMPurify.sanitize(value, {
    ALLOWED_TAGS: [
      'p', 'br', 'b', 'i', 'em', 'strong', 'u', 'ul', 'ol', 'li',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'blockquote'
    ],
    ALLOWED_ATTR: ['href', 'target', 'rel'],
  });
  
  // Check if the content is not empty after sanitization
  const div = document.createElement('div');
  div.innerHTML = clean;
  const textContent = div.textContent || div.innerText;
  
  return textContent.trim().length > 0;
};

export const eventSchema = z.object({
  title: z.string()
    .min(3, 'Title must be at least 3 characters')
    .max(100, 'Title must be less than 100 characters'),
  
  description: z.string()
    .min(10, 'Description must be at least 10 characters')
    .max(10000, 'Description must be less than 10000 characters')
    .refine(validateDescription, 'Invalid description format'),
  
  location: z.object({
    name: z.string().min(1, 'Location name is required'),
    address: z.string().min(1, 'Address is required'),
    latitude: z.string().regex(/^-?\d+\.?\d*$/, 'Invalid latitude'),
    longitude: z.string().regex(/^-?\d+\.?\d*$/, 'Invalid longitude'),
  }),
  
  startDate: z.string().datetime(),
  endDate: z.string().datetime(),
  
  categories: z.array(z.string())
    .min(1, 'At least one category is required'),
  
  price: z.object({
    min: z.number().min(0).optional(),
    max: z.number().min(0).optional(),
  }).nullable().optional(),
  
  maxAttendees: z.string()
    .regex(/^\d*$/, 'Must be a number')
    .optional(),
  
  imageUrl: z.string()
    .url('Invalid image URL')
    .optional(),
  
  status: z.enum(['pending', 'approved', 'rejected', 'draft'])
    .optional(),
  
  organizer: z.object({
    id: z.string(),
    name: z.string(),
    imageUrl: z.string().optional()
  }),
  
  moderatedBy: z.object({
    id: z.string(),
    name: z.string(),
    photoURL: z.string().optional()
  }).optional(),
  
  moderatedAt: z.string()
    .datetime()
    .optional(),
  
  moderationNotes: z.string()
    .optional(),
  
  ticketUrl: z.string()
    .url('Invalid ticket URL')
    .optional()
    .nullable(),
  
  shares: z.number()
    .min(0)
    .optional()
});

export const bulkEventSchema = z.object({
  title: z.string()
    .min(3, 'Title must be at least 3 characters')
    .max(100, 'Title must be less than 100 characters'),
  
  description: z.string()
    .min(10, 'Description must be at least 10 characters')
    .max(10000, 'Description must be less than 10000 characters')
    .refine(validateDescription, 'Invalid description format'),
  
  location: z.object({
    name: z.string().min(1, 'Location name is required'),
    address: z.string().min(1, 'Address is required'),
    latitude: z.string().regex(/^-?\d+\.?\d*$/, 'Invalid latitude'),
    longitude: z.string().regex(/^-?\d+\.?\d*$/, 'Invalid longitude'),
  }),
  
  startDate: z.string()
    .refine(validateDateTime, 'Invalid start date format. Use ISO 8601 format'),
  
  endDate: z.string()
    .refine(validateDateTime, 'Invalid end date format. Use ISO 8601 format'),
  
  categories: z.array(z.string())
    .min(1, 'At least one category is required'),
  
  price: z.object({
    min: z.number().min(0),
    max: z.number().min(0)
  }).optional(),
  
  imageUrl: z.string()
    .url('Invalid image URL')
    .optional(),
  
  organizer: z.object({
    id: z.string().optional(),
    name: z.string().min(1, 'Organizer name is required'),
    imageUrl: z.string().optional()
  }),

  ticketUrl: z.string()
    .url('Invalid ticket URL')
    .optional(),

  maxAttendees: z.string()
    .optional(),

  status: z.enum(['pending', 'draft'])
    .optional()
    .default('pending'),
});

export const userSchema = z.object({
  displayName: z.string()
    .min(2, 'Display name must be at least 2 characters')
    .max(50, 'Display name must be less than 50 characters')
    .regex(/^[a-zA-Z0-9\s\-_]+$/, 'Display name contains invalid characters'),
  
  username: z.string()
    .min(3, 'Username must be at least 3 characters')
    .max(30, 'Username must be less than 30 characters')
    .regex(/^[a-zA-Z0-9_]+$/, 'Username can only contain letters, numbers, and underscores')
    .optional(),
  
  bio: z.string()
    .max(500, 'Bio must be less than 500 characters')
    .optional(),
  
  website: z.string()
    .url('Invalid website URL')
    .optional(),
  
  role: z.enum(['attendee', 'host', 'moderator', 'admin']),
  
  email: z.string()
    .email('Invalid email address'),
  
  photoURL: z.string()
    .url('Invalid photo URL')
    .optional(),
  
  createdAt: z.string()
    .datetime()
    .optional(),
  
  updatedAt: z.string()
    .datetime()
    .optional(),
  
  lastLoginAt: z.string()
    .datetime()
    .optional(),
  
  collections: z.object({
    saved: z.array(z.string()),
    liked: z.array(z.string())
  }).optional()
});

export const commentSchema = z.object({
  content: z.string()
    .min(1, 'Comment cannot be empty')
    .max(1000, 'Comment must be less than 1000 characters')
    .refine(validateDescription, 'Invalid comment format'),
  
  userId: z.string(),
  eventId: z.string(),
  parentId: z.string().optional(),
  
  createdAt: z.string()
    .datetime()
    .optional(),
  
  likes: z.number()
    .min(0)
    .optional()
});

export const notificationSchema = z.object({
  userId: z.string(),
  type: z.enum([
    'event_moderation',
    'comment_reply',
    'event_reminder',
    'event_update',
    'moderator_request',
    'moderator_approval'
  ]),
  message: z.string(),
  eventId: z.string().optional(),
  data: z.record(z.any()).optional(),
  read: z.boolean().default(false),
  createdAt: z.string().datetime().optional()
});

export const collectionSchema = z.object({
  userId: z.string(),
  eventId: z.string(),
  type: z.enum(['saved', 'liked']),
  createdAt: z.string().datetime().optional()
});

// Validate array of events
export const bulkEventsSchema = z.array(bulkEventSchema);