import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { getUserEvents } from '../services/events';
import { getUserCollections } from '../services/collections';
import EventList from '../components/EventList';
import EditProfileModal from '../components/EditProfileModal';
import { Calendar, Users, Heart, Bookmark, Edit2, MapPin, Mail, Link } from 'lucide-react';

export default function ProfilePage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [activeTab, setActiveTab] = useState<'saved' | 'hosted' | 'liked'>('saved');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { data: hostedEvents = [] } = useQuery({
    queryKey: ['hosted-events', user?.id],
    queryFn: () => getUserEvents(user!.id),
    enabled: !!user && user.role === 'host',
  });

  const { data: collections = { saved: [], liked: [] } } = useQuery({
    queryKey: ['user-collections', user?.id],
    queryFn: () => getUserCollections(user!.id),
    enabled: !!user,
  });

  if (!user) return null;

  const tabs = [
    { id: 'saved', label: 'Saved Events', icon: Bookmark },
    ...(user.role === 'host' ? [
      { id: 'hosted', label: 'Your Events', icon: Calendar }
    ] : []),
    { id: 'liked', label: 'Liked Events', icon: Heart },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <div className="relative">
          {/* Cover Photo */}
          <div className="h-48 w-full bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg" />
          
          {/* Profile Info */}
          <div className="absolute -bottom-16 left-4 sm:left-8">
            <div className="relative">
              {user.photoURL ? (
                <img
                  src={user.photoURL}
                  alt={user.displayName}
                  className="w-32 h-32 rounded-full border-4 border-white"
                />
              ) : (
                <div className="w-32 h-32 rounded-full bg-indigo-100 flex items-center justify-center border-4 border-white">
                  <Users className="h-12 w-12 text-indigo-600" />
                </div>
              )}
            </div>
          </div>

          {/* Edit Profile Button */}
          <div className="absolute top-4 right-4">
            <button
              onClick={() => setIsEditModalOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black bg-opacity-20 hover:bg-opacity-30 backdrop-blur-sm"
            >
              <Edit2 className="h-4 w-4 mr-2" />
              Edit Profile
            </button>
          </div>
        </div>

        {/* Profile Details */}
        <div className="mt-20 sm:ml-8">
          <div className="space-y-1">
            <h1 className="text-2xl font-bold text-gray-900">{user.displayName}</h1>
            {user.username && (
              <p className="text-gray-500">@{user.username}</p>
            )}
          </div>

          {user.bio && (
            <p className="mt-4 text-gray-700 max-w-2xl">{user.bio}</p>
          )}

          <div className="mt-4 flex flex-wrap gap-4">
            <div className="flex items-center text-gray-500">
              <MapPin className="h-4 w-4 mr-1" />
              <span>Amsterdam, Netherlands</span>
            </div>
            {user.email && (
              <div className="flex items-center text-gray-500">
                <Mail className="h-4 w-4 mr-1" />
                <span>{user.email}</span>
              </div>
            )}
            {user.website && (
              <div className="flex items-center text-gray-500">
                <Link className="h-4 w-4 mr-1" />
                <a href={user.website} target="_blank" rel="noopener noreferrer" className="hover:text-indigo-600">
                  {user.website.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
          </div>

          <div className="mt-6 flex items-center space-x-6">
            <div>
              <span className="text-2xl font-bold text-gray-900">{hostedEvents.length}</span>
              <span className="ml-1 text-gray-500">Events</span>
            </div>
            <div>
              <span className="text-2xl font-bold text-gray-900">{collections.liked.length}</span>
              <span className="ml-1 text-gray-500">Likes</span>
            </div>
            <div>
              <span className="text-2xl font-bold text-gray-900">{collections.saved.length}</span>
              <span className="ml-1 text-gray-500">Saved</span>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200 mt-8">
          <nav className="-mb-px flex space-x-8">
            {tabs.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setActiveTab(id as any)}
                className={`
                  flex items-center py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === id
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                `}
              >
                <Icon className="h-5 w-5 mr-2" />
                {label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Content */}
      {activeTab === 'hosted' && user.role === 'host' && (
        <EventList 
          events={hostedEvents} 
          onEventClick={(event) => navigate(`/event/${event.id}`)}
          showManageButton 
        />
      )}

      {activeTab === 'saved' && (
        <EventList 
          events={collections.saved} 
          onEventClick={(event) => navigate(`/event/${event.id}`)}
        />
      )}

      {activeTab === 'liked' && (
        <EventList 
          events={collections.liked} 
          onEventClick={(event) => navigate(`/event/${event.id}`)}
        />
      )}

      <EditProfileModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        currentUser={user}
      />
    </div>
  );
}