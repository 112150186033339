import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { enableNetwork, disableNetwork } from 'firebase/firestore';

export function useFirebaseConnection() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isConnected, setIsConnected] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      enableNetwork(db)
        .then(() => {
          setIsConnected(true);
          // Trigger a re-fetch of data when coming back online
          window.dispatchEvent(new CustomEvent('firebase-reconnected'));
        })
        .catch(error => {
          console.error('Error reconnecting to Firestore:', error);
          setError(error);
        });
    };

    const handleOffline = () => {
      setIsOnline(false);
      disableNetwork(db)
        .then(() => {
          setIsConnected(false);
          // Trigger fallback to offline data
          window.dispatchEvent(new CustomEvent('firebase-disconnected'));
        })
        .catch(error => {
          console.error('Error disconnecting from Firestore:', error);
          setError(error);
        });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial connection check
    if (!navigator.onLine) {
      handleOffline();
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline, isConnected, error };
}