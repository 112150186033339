import React, { useState } from 'react';
import { AlertTriangle, Upload } from 'lucide-react';
import { Event } from '../types/event';
import { bulkEventSchema } from '../utils/validation';
import { EVENT_CATEGORIES } from '../constants/categories';
import DateRangePicker from './DateRangePicker';
import LocationPicker from './LocationPicker';
import RichTextEditor from './RichTextEditor';

interface EventValidatorProps {
  event: Partial<Event>;
  onValidation: (event: any) => void;
  isSubmitting: boolean;
}

export default function EventValidator({ 
  event, 
  onValidation,
  isSubmitting 
}: EventValidatorProps) {
  const [validatedEvent, setValidatedEvent] = useState<Partial<Event>>(event);
  const [validationErrors, setValidationErrors] = useState<{ field: string; message: string }[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(
    event.startDate ? new Date(event.startDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    event.endDate ? new Date(event.endDate) : null
  );

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      handleFieldChange('imageUrl', URL.createObjectURL(file));
    }
  };

  const validateEvent = (eventData: Partial<Event>) => {
    try {
      const result = bulkEventSchema.parse(eventData);
      setValidationErrors([]);
      onValidation({ ...result, _validation: { isValid: true, errors: [] } });
      return true;
    } catch (error: any) {
      const errors = error.errors.map((err: any) => ({
        field: err.path.join('.'),
        message: err.message
      }));
      setValidationErrors(errors);
      onValidation({ ...eventData, _validation: { isValid: false, errors } });
      return false;
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    const updatedEvent = {
      ...validatedEvent,
      [field]: value
    };
    setValidatedEvent(updatedEvent);
    validateEvent(updatedEvent);
  };

  const handleDateChange = (type: 'start' | 'end', date: Date | null) => {
    if (type === 'start') {
      setStartDate(date);
      handleFieldChange('startDate', date?.toISOString());
    } else {
      setEndDate(date);
      handleFieldChange('endDate', date?.toISOString());
    }
  };

  return (
    <div className="space-y-6">
      {validationErrors.length > 0 && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Validation Errors
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc pl-5 space-y-1">
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error.message}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-6">
        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Event Image
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
            {(imagePreview || validatedEvent.imageUrl) ? (
              <div className="relative">
                <img
                  src={imagePreview || validatedEvent.imageUrl}
                  alt="Preview"
                  className="max-h-64 rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => {
                    setImageFile(null);
                    setImagePreview(null);
                    handleFieldChange('imageUrl', null);
                  }}
                  className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
                >
                  <Upload className="h-5 w-5 text-gray-600" />
                </button>
              </div>
            ) : (
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-brand-600 hover:text-brand-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
              </div>
            )}
          </div>
        </div>

        {/* Title */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Event Title
          </label>
          <input
            type="text"
            value={validatedEvent.title || ''}
            onChange={(e) => handleFieldChange('title', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
          />
        </div>

        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <RichTextEditor
            value={validatedEvent.description || ''}
            onChange={(content) => handleFieldChange('description', content)}
          />
        </div>

        {/* Date and Time */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Event Date and Time
          </label>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(date) => handleDateChange('start', date)}
            onEndDateChange={(date) => handleDateChange('end', date)}
          />
        </div>

        {/* Location */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Location
          </label>
          <LocationPicker
            defaultValue={validatedEvent.location}
            onChange={(location) => handleFieldChange('location', location)}
          />
        </div>

        {/* Categories */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Categories
          </label>
          <div className="mt-2 flex flex-wrap gap-2">
            {EVENT_CATEGORIES.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => {
                  const categories = validatedEvent.categories || [];
                  const newCategories = categories.includes(category)
                    ? categories.filter(c => c !== category)
                    : [...categories, category];
                  handleFieldChange('categories', newCategories);
                }}
                className={`px-3 py-1 rounded-full text-sm ${
                  validatedEvent.categories?.includes(category)
                    ? 'bg-brand-100 text-brand-600'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Price */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Price
          </label>
          <div className="mt-2 grid grid-cols-2 gap-4">
            <div>
              <label className="block text-xs text-gray-500">Min Price</label>
              <input
                type="number"
                step="0.01"
                value={validatedEvent.price?.min || ''}
                onChange={(e) => handleFieldChange('price', {
                  ...validatedEvent.price,
                  min: parseFloat(e.target.value)
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
              />
            </div>
            <div>
              <label className="block text-xs text-gray-500">Max Price</label>
              <input
                type="number"
                step="0.01"
                value={validatedEvent.price?.max || ''}
                onChange={(e) => handleFieldChange('price', {
                  ...validatedEvent.price,
                  max: parseFloat(e.target.value)
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
              />
            </div>
          </div>
        </div>

        {/* Ticket URL */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ticket URL
          </label>
          <input
            type="url"
            value={validatedEvent.ticketUrl || ''}
            onChange={(e) => handleFieldChange('ticketUrl', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
            placeholder="https://..."
          />
        </div>

        {/* Organizer */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Organizer
          </label>
          <input
            type="text"
            value={validatedEvent.organizer?.name || ''}
            onChange={(e) => handleFieldChange('organizer', {
              ...validatedEvent.organizer,
              name: e.target.value
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500"
          />
        </div>
      </div>
    </div>
  );
}