import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const languages = {
  en: { name: 'English', nativeName: 'English' },
  nl: { name: 'Dutch', nativeName: 'Nederlands' },
  fr: { name: 'French', nativeName: 'Français' },
  es: { name: 'Spanish', nativeName: 'Español' }
};

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const changeLanguage = async (lng: string) => {
    try {
      await i18n.changeLanguage(lng);
      localStorage.setItem('i18nextLng', lng);
      setIsOpen(false);
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  const currentLanguage = i18n.language?.split('-')[0] || 'en';

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center space-x-2 px-3 py-2 rounded-md ${
          isHomePage ? 'text-white hover:bg-white/10' : 'text-gray-900 hover:bg-gray-100'
        } transition-colors duration-200`}
      >
        <Globe className={`h-5 w-5 ${isHomePage ? 'text-white' : 'text-gray-900'}`} />
        <span className="text-sm font-medium">
          {languages[currentLanguage as keyof typeof languages]?.nativeName || 'English'}
        </span>
      </button>

      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-20"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-30">
            {Object.entries(languages).map(([code, { nativeName }]) => (
              <button
                key={code}
                onClick={() => changeLanguage(code)}
                className={`w-full text-left px-4 py-2 text-sm hover:bg-gray-100 ${
                  currentLanguage === code ? 'font-medium text-brand-600' : 'text-gray-700'
                }`}
              >
                {nativeName}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}