import React from 'react';
import { X, User, Calendar, Shield } from 'lucide-react';
import { UserRole } from '../types/auth';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useAuthStore } from '../store/authStore';
import { createNotification } from '../services/notifications';

interface RoleSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  initialUserData: any;
}

export default function RoleSelectionModal({ isOpen, onClose, userId, initialUserData }: RoleSelectionModalProps) {
  const { setUser } = useAuthStore();
  const [selectedRole, setSelectedRole] = React.useState<UserRole>('attendee');
  const [moderatorMotivation, setModeratorMotivation] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleRoleSelect = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Validate moderator motivation if role is moderator
      if (selectedRole === 'moderator' && (!moderatorMotivation || moderatorMotivation.trim().length < 50)) {
        setError('Please provide a detailed explanation (at least 50 characters) of why you want to be a moderator.');
        return;
      }

      const userData = {
        ...initialUserData,
        role: selectedRole,
        // Set approved status based on role
        approved: selectedRole !== 'moderator', // Only moderators need approval
        approvedAt: selectedRole !== 'moderator' ? new Date().toISOString() : null,
        approvedBy: null,
        ...(selectedRole === 'moderator' && {
          moderatorMotivation: moderatorMotivation.trim()
        })
      };

      // If user selected moderator role, create notification for admins
      if (selectedRole === 'moderator') {
        // Notify admins about new moderator request
        const adminQuery = query(
          collection(db, 'users'),
          where('role', '==', 'admin')
        );
        const adminSnapshot = await getDocs(adminQuery);
        
        // Create notifications for all admins
        const notificationPromises = adminSnapshot.docs.map(adminDoc => 
          createNotification({
            userId: adminDoc.id,
            type: 'moderator_request',
            message: `New moderator request from ${userData.displayName}`,
            data: {
              requesterId: userId,
              requesterName: userData.displayName,
              motivation: moderatorMotivation
            }
          })
        );
        
        await Promise.all(notificationPromises);

        // Show pending status message to user
        userData.pendingApproval = true;
      }

      await setDoc(doc(db, 'users', userId), userData);
      setUser(userData);
      onClose();
    } catch (error: any) {
      setError(error.message || 'Failed to set user role');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-md w-full relative max-h-[90vh] flex flex-col">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>

        <div className="p-8 overflow-y-auto">
          <h2 className="text-2xl font-bold mb-6">Choose Your Role</h2>
          <p className="text-gray-600 mb-6">
            Select how you want to use ThrillDash. You can be an attendee, event host, or moderator.
          </p>

          <div className="space-y-4 mb-6">
            <button
              onClick={() => setSelectedRole('attendee')}
              className={`w-full flex items-center p-4 border rounded-lg ${
                selectedRole === 'attendee' ? 'border-indigo-500 bg-indigo-50' : 'border-gray-200'
              }`}
            >
              <User className="h-6 w-6 text-indigo-600 mr-3" />
              <div className="text-left">
                <h3 className="font-medium">Attendee</h3>
                <p className="text-sm text-gray-500">Discover and attend amazing events</p>
              </div>
            </button>

            <button
              onClick={() => setSelectedRole('host')}
              className={`w-full flex items-center p-4 border rounded-lg ${
                selectedRole === 'host' ? 'border-indigo-500 bg-indigo-50' : 'border-gray-200'
              }`}
            >
              <Calendar className="h-6 w-6 text-indigo-600 mr-3" />
              <div className="text-left">
                <h3 className="font-medium">Host</h3>
                <p className="text-sm text-gray-500">Create and manage your own events</p>
              </div>
            </button>

            <button
              onClick={() => setSelectedRole('moderator')}
              className={`w-full flex items-center p-4 border rounded-lg ${
                selectedRole === 'moderator' ? 'border-indigo-500 bg-indigo-50' : 'border-gray-200'
              }`}
            >
              <Shield className="h-6 w-6 text-indigo-600 mr-3" />
              <div className="text-left">
                <h3 className="font-medium">Moderator</h3>
                <p className="text-sm text-gray-500">Help maintain community standards</p>
              </div>
            </button>
          </div>

          {selectedRole === 'moderator' && (
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Why do you want to be a moderator?
              </label>
              <textarea
                value={moderatorMotivation}
                onChange={(e) => setModeratorMotivation(e.target.value)}
                placeholder="Please explain your motivation and any relevant experience you have..."
                className="w-full h-32 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
              <p className="mt-1 text-sm text-gray-500">
                {moderatorMotivation.length}/500 characters (minimum 50)
              </p>
            </div>
          )}

          {selectedRole === 'moderator' && (
            <div className="mb-6 p-4 bg-yellow-50 rounded-lg">
              <p className="text-sm text-yellow-700">
                Note: Moderator accounts require additional verification. Our team will review your request and contact you via email.
                You will not have moderator access until your request is approved.
              </p>
            </div>
          )}

          {error && (
            <p className="text-red-500 text-sm mb-4">{error}</p>
          )}

          <button
            onClick={handleRoleSelect}
            disabled={isLoading}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {isLoading ? 'Setting role...' : 'Continue'}
          </button>
        </div>
      </div>
    </div>
  );
}