import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { X, LogIn, LogOut, User, ChevronDown, Shield, AlertCircle } from 'lucide-react';
import { SignUpData, SignInData } from '../types/auth';
import { signUp, signIn, signInWithGoogle, signInWithMicrosoft } from '../services/auth';
import { useAuthStore } from '../store/authStore';
import RoleSelectionModal from './RoleSelectionModal';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMode?: 'signin' | 'signup';
}

export default function AuthModal({
  isOpen,
  onClose,
  initialMode = 'signin'
}: AuthModalProps) {
  const [mode, setMode] = useState<'signin' | 'signup'>(initialMode);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const { setUser } = useAuthStore();
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [pendingOAuthData, setPendingOAuthData] = useState<any>(null);
  const { t } = useTranslation();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  useEffect(() => {
    if (isOpen) {
      setMode(initialMode);
      reset();
      setError(null);
      setRecaptchaToken(null);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  }, [isOpen, initialMode, reset]);

  const handleSignUp = async (data: any) => {
    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA verification');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const signUpData: SignUpData = {
        ...data,
        role: 'attendee', // Default role
        recaptchaToken
      };

      const user = await signUp(signUpData);
      setUser(user);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setRecaptchaToken(null);
      onClose();
    } catch (error: any) {
      console.error('Signup error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = async (data: SignInData) => {
    try {
      setIsLoading(true);
      setError(null);
      const user = await signIn(data);
      setUser(user);
      onClose();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOAuthSignIn = async (signInMethod: () => Promise<any>) => {
    try {
      setIsLoading(true);
      setError(null);
      const { user, isNewUser, initialUserData } = await signInMethod();
      
      if (isNewUser) {
        setPendingOAuthData(initialUserData);
        setShowRoleModal(true);
      } else {
        setUser(user);
        onClose();
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    setError(null);
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <X className="h-6 w-6" />
          </button>

          <h2 className="text-2xl font-bold mb-6">
            {mode === 'signin' ? t('auth.welcome_back') : t('auth.create_account')}
          </h2>

          {/* OAuth Buttons */}
          <div className="space-y-2 mb-6">
            <button
              onClick={() => handleOAuthSignIn(signInWithGoogle)}
              disabled={isLoading}
              className="w-full flex items-center justify-center bg-white border border-gray-300 rounded-md py-2 px-4 hover:bg-gray-50 disabled:opacity-50"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                className="w-5 h-5 mr-2"
              />
              {t('auth.continue_with', { provider: 'Google' })}
            </button>

            <button
              onClick={() => handleOAuthSignIn(signInWithMicrosoft)}
              disabled={isLoading}
              className="w-full flex items-center justify-center bg-white border border-gray-300 rounded-md py-2 px-4 hover:bg-gray-50 disabled:opacity-50"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg"
                alt="Microsoft"
                className="w-5 h-5 mr-2"
              />
              {t('auth.continue_with', { provider: 'Microsoft' })}
            </button>
          </div>

          <div className="relative mb-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">{t('auth.or_email')}</span>
            </div>
          </div>

          <form onSubmit={handleSubmit(mode === 'signin' ? handleSignIn : handleSignUp)}>
            {mode === 'signup' && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t('auth.display_name')}
                </label>
                <input
                  type="text"
                  {...register('displayName', { required: 'Display name is required' })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {errors.displayName && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.displayName.message as string}
                  </p>
                )}
              </div>
            )}

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t('auth.email')}
              </label>
              <input
                type="email"
                {...register('email', { required: 'Email is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.email.message as string}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700">
                {t('auth.password')}
              </label>
              <input
                type="password"
                {...register('password', { required: 'Password is required' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.password.message as string}
                </p>
              )}
            </div>

            {mode === 'signup' && (
              <div className="mb-6">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lf6GXgqAAAAAERUy0b6ch4hjMFZ6Vkjmowvwwd3"
                  onChange={handleRecaptchaChange}
                />
              </div>
            )}

            {error && (
              <div className="mb-4 p-3 bg-red-50 rounded-md flex items-start">
                <AlertCircle className="h-5 w-5 text-red-400 mt-0.5 mr-2" />
                <p className="text-sm text-red-600">{error}</p>
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading || (mode === 'signup' && !recaptchaToken)}
              className="w-full bg-brand-600 text-white py-2 px-4 rounded-md hover:bg-brand-700 disabled:opacity-50"
            >
              {isLoading ? 'Please wait...' : mode === 'signin' ? t('auth.sign_in') : t('auth.sign_up')}
            </button>
          </form>

          <p className="mt-4 text-center text-sm text-gray-600">
            {mode === 'signin' ? (
              <>
                {t('auth.no_account')}{' '}
                <button
                  onClick={() => setMode('signup')}
                  className="text-brand-600 hover:text-brand-500"
                >
                  {t('auth.sign_up')}
                </button>
              </>
            ) : (
              <>
                {t('auth.already_account')}{' '}
                <button
                  onClick={() => setMode('signin')}
                  className="text-brand-600 hover:text-brand-500"
                >
                  {t('auth.sign_in')}
                </button>
              </>
            )}
          </p>
        </div>
      </div>

      {showRoleModal && pendingOAuthData && (
        <RoleSelectionModal
          isOpen={showRoleModal}
          onClose={() => {
            setShowRoleModal(false);
            setPendingOAuthData(null);
            onClose();
          }}
          userId={pendingOAuthData.id}
          initialUserData={pendingOAuthData}
        />
      )}
    </>
  );
}