import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { app } from './firebase';

const storage = getStorage(app);

export const uploadImage = async (file: File): Promise<string> => {
  const fileExtension = file.name.split('.').pop();
  const fileName = `${uuidv4()}.${fileExtension}`;
  const storageRef = ref(storage, `images/${fileName}`);
  
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};