import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { Upload, AlertTriangle, Check, X, ChevronRight, ChevronLeft, Save } from 'lucide-react';
import { Event } from '../types/event';
import { createEvent } from '../services/events';
import EventValidator from '../components/EventValidator';
import { bulkEventsSchema } from '../utils/validation';

interface ParsedEvent extends Partial<Event> {
  _validation?: {
    isValid: boolean;
    errors: string[];
  };
}

export default function AdminBulkUploadPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [events, setEvents] = useState<ParsedEvent[]>([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCount, setSuccessCount] = useState(0);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  // Redirect if not admin
  if (!user || user.role !== 'admin') {
    navigate('/');
    return null;
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    try {
      const parsedEvents: ParsedEvent[] = [];
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const text = await file.text();
        const json = JSON.parse(text);
        
        // Handle both single event and array of events
        const eventArray = Array.isArray(json) ? json : [json];
        
        // Validate events against schema
        const validatedEvents = bulkEventsSchema.parse(eventArray);
        parsedEvents.push(...validatedEvents);
      }

      setEvents(parsedEvents);
      setCurrentEventIndex(0);
      setError(null);
      setSuccessCount(0);
      setReadyToSubmit(false);
    } catch (err: any) {
      setError('Error parsing JSON files: ' + err.message);
    }
  };

  const handleEventValidation = useCallback((validatedEvent: ParsedEvent) => {
    setEvents(prev => {
      const newEvents = [...prev];
      newEvents[currentEventIndex] = validatedEvent;

      // Check if all events are validated
      const allValidated = newEvents.every(event => event._validation?.isValid);
      setReadyToSubmit(allValidated);

      return newEvents;
    });
  }, [currentEventIndex]);

  const handleSubmitAllEvents = async () => {
    if (!readyToSubmit) {
      setError('Please validate all events before submitting');
      return;
    }

    try {
      setIsUploading(true);
      setError(null);

      // Submit all validated events
      await Promise.all(events.map(async (event) => {
        const { _validation, ...eventData } = event;
        await createEvent({
          ...eventData as Omit<Event, 'id'>,
          status: isDraft ? 'draft' : 'pending',
          organizer: {
            id: user.id,
            name: user.displayName,
            imageUrl: user.photoURL || ''
          }
        });
      }));

      setSuccessCount(events.length);

      // Reset state after successful submission
      setTimeout(() => {
        setEvents([]);
        setCurrentEventIndex(0);
        setReadyToSubmit(false);
      }, 3000);
    } catch (err: any) {
      setError('Error submitting events: ' + err.message);
    } finally {
      setIsUploading(false);
    }
  };

  const currentEvent = events[currentEventIndex];

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Bulk Event Upload</h1>
        <p className="mt-2 text-gray-600">
          Upload JSON files containing event data for validation and submission
        </p>
      </div>

      {events.length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="max-w-xl mx-auto text-center">
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <h2 className="mt-4 text-lg font-medium text-gray-900">Upload Event Files</h2>
            <p className="mt-2 text-sm text-gray-500">
              Upload one or more JSON files containing event data
            </p>
            <label className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 cursor-pointer">
              Select Files
              <input
                type="file"
                accept="application/json"
                onChange={handleFileUpload}
                multiple
                className="sr-only"
              />
            </label>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm p-8">
          {/* Progress indicator */}
          <div className="mb-8">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-700">
                Event {currentEventIndex + 1} of {events.length}
              </span>
              <span className="text-sm text-gray-500">
                {successCount} submitted successfully
              </span>
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
              <div
                className="h-2 bg-brand-600 rounded-full transition-all"
                style={{ width: `${((currentEventIndex + 1) / events.length) * 100}%` }}
              />
            </div>
          </div>

          {/* Navigation */}
          <div className="flex items-center justify-between mb-6">
            <button
              onClick={() => setCurrentEventIndex(prev => prev - 1)}
              disabled={currentEventIndex === 0}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              <ChevronLeft className="h-5 w-5 mr-1" />
              Previous
            </button>
            <button
              onClick={() => setCurrentEventIndex(prev => prev + 1)}
              disabled={currentEventIndex === events.length - 1}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Next
              <ChevronRight className="h-5 w-5 ml-1" />
            </button>
          </div>

          {/* Event Validator */}
          {currentEvent && (
            <div>
              <EventValidator
                event={currentEvent}
                onValidation={handleEventValidation}
                isSubmitting={isUploading}
              />
              
              <div className="mt-6 flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isDraft"
                    checked={isDraft}
                    onChange={(e) => setIsDraft(e.target.checked)}
                    className="h-4 w-4 text-brand-600 focus:ring-brand-500 border-gray-300 rounded"
                  />
                  <label htmlFor="isDraft" className="ml-2 text-sm text-gray-700">
                    Save as drafts
                  </label>
                </div>

                {readyToSubmit && (
                  <button
                    onClick={handleSubmitAllEvents}
                    disabled={isUploading}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
                  >
                    <Save className="h-4 w-4 mr-2" />
                    {isUploading ? 'Submitting...' : 'Submit All Events'}
                  </button>
                )}
              </div>
            </div>
          )}

          {error && (
            <div className="mt-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertTriangle className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <p className="mt-2 text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          {successCount > 0 && (
            <div className="mt-4 p-4 bg-green-50 rounded-md">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">Success</h3>
                  <p className="mt-2 text-sm text-green-700">
                    Successfully submitted {successCount} events
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}