import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { 
  getFirestore, 
  connectFirestoreEmulator, 
  enableMultiTabIndexedDbPersistence,
  enableNetwork,
  disableNetwork,
  waitForPendingWrites,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED
} from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { config } from '../config/appConfig';

// Initialize Firebase with optimized Firestore settings
const app = initializeApp(config.firebase);
const auth = getAuth(app);

// Initialize Firestore with correct persistence settings
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalForceLongPolling: true, // Use only one long polling option
});

const storage = getStorage(app);

// Network state management
let isNetworkEnabled = true;
let connectionTimeout: NodeJS.Timeout | null = null;

// Enhanced persistence initialization with retry mechanism
const initializePersistence = async (retryCount = 3, delay = 1000) => {
  for (let i = 0; i < retryCount; i++) {
    try {
      await setPersistence(auth, browserLocalPersistence);
      
      if (!config.emulators.enabled) {
        await enableMultiTabIndexedDbPersistence(db);
      }
      
      console.log('Firebase persistence enabled successfully');
      return true;
    } catch (error: any) {
      if (error.code === 'failed-precondition') {
        console.warn('Multiple tabs open, persistence enabled in another tab');
        break;
      } else if (error.code === 'unimplemented') {
        console.warn('Browser does not support persistence');
        break;
      } else if (i < retryCount - 1) {
        console.warn(`Persistence initialization attempt ${i + 1} failed, retrying...`);
        await new Promise(resolve => setTimeout(resolve, delay));
        continue;
      }
      console.error('Failed to enable persistence:', error);
    }
  }
  return false;
};

// Enhanced network state management
const handleNetworkChange = async (online: boolean) => {
  try {
    if (online && !isNetworkEnabled) {
      await enableNetwork(db);
      isNetworkEnabled = true;
      console.log('Firestore network connection restored');
      
      // Clear any existing timeout
      if (connectionTimeout) {
        clearTimeout(connectionTimeout);
        connectionTimeout = null;
      }
    } else if (!online && isNetworkEnabled) {
      await disableNetwork(db);
      isNetworkEnabled = false;
      console.log('Firestore network connection disabled');
      
      // Set a timeout to retry connection
      connectionTimeout = setTimeout(() => {
        if (!isNetworkEnabled) {
          handleNetworkChange(true).catch(console.error);
        }
      }, 30000); // Retry after 30 seconds
    }
  } catch (error) {
    console.error('Error managing network state:', error);
  }
};

// Network state listeners
window.addEventListener('online', () => handleNetworkChange(true));
window.addEventListener('offline', () => handleNetworkChange(false));

// Initialize emulators in development
if (config.emulators.enabled) {
  const { host, ports } = config.emulators;
  try {
    connectFirestoreEmulator(db, host, ports.firestore);
    connectStorageEmulator(storage, host, ports.storage);
    console.log('Firebase emulators connected successfully');
  } catch (error) {
    console.error('Failed to connect to emulators:', error);
  }
}

// Configure Auth Providers with enhanced error handling
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.addScope('user.read');
microsoftProvider.setCustomParameters({
  prompt: 'select_account'
});

// Enhanced network state management functions
export const toggleNetwork = async (enable: boolean): Promise<void> => {
  try {
    await handleNetworkChange(enable);
  } catch (error) {
    console.error('Error toggling network state:', error);
    throw error;
  }
};

export const waitForSync = async (): Promise<void> => {
  try {
    await waitForPendingWrites(db);
  } catch (error) {
    console.error('Error waiting for pending writes:', error);
    throw error;
  }
};

// Initialize analytics with error handling
export const initializeAnalytics = async () => {
  if (!import.meta.env.PROD) {
    console.log('Analytics disabled in development');
    return null;
  }

  try {
    const analyticsSupported = await isSupported();
    if (analyticsSupported) {
      return getAnalytics(app);
    }
    return null;
  } catch (error) {
    console.warn('Analytics initialization skipped:', error);
    return null;
  }
};

// Auth functions with enhanced error handling and offline support
export const signInWithGoogle = async () => {
  try {
    if (!navigator.onLine) {
      throw new Error('Cannot sign in while offline');
    }
    const result = await signInWithPopup(auth, googleProvider);
    await waitForSync();
    return result;
  } catch (error: any) {
    console.error('Google sign in failed:', error);
    throw new Error(error.message || 'Google sign in failed');
  }
};

export const signInWithMicrosoft = async () => {
  try {
    if (!navigator.onLine) {
      throw new Error('Cannot sign in while offline');
    }
    const result = await signInWithPopup(auth, microsoftProvider);
    await waitForSync();
    return result;
  } catch (error: any) {
    console.error('Microsoft sign in failed:', error);
    throw new Error(error.message || 'Microsoft sign in failed');
  }
};

export const signOutUser = async () => {
  try {
    await waitForSync();
    await signOut(auth);
  } catch (error: any) {
    console.error('Sign out failed:', error);
    throw new Error(error.message || 'Sign out failed');
  }
};

// Initialize persistence on app start
initializePersistence().then((success) => {
  if (success) {
    console.log('Firebase initialization complete');
  } else {
    console.warn('Firebase initialization completed with limited functionality');
  }
});

// Check initial network state
handleNetworkChange(navigator.onLine);

// Export initialized services
export { app, auth, db, storage };